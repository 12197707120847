import React, {useEffect, useState} from "react";
import "./index.css";
import "../../../css/style.css";
import "../../../css/formRegister.css";
import userApi from "../../../services/userApi";

import {useNavigate} from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ProgressBar from "../../../components/progressBar";
import eventApi from "../../../services/eventApi";

const Webinar = () => {
  useEffect(() => {
    handleFetchAllEvents();
  }, []);

  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [email, setemail] = useState("");
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [phone, setphone] = useState("");
  const [events, setEvents] = useState([]);
  const [eventInfo, setEventInfo] = useState({});
  const [isVerified, setIsVerified] = useState(false);
  const [showpass, setpass] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showError, setShowError] = useState(false);

  // const [alertRed, setAlertRed] = useState(false);
  const [error, setError] = useState(false);
  const [formMessage, setFormMessage] = useState(false);
  const [succesfullyRegistered, setSuccesfullyRegistered] = useState(false);
  const openModal = () => {
    setModal(!modal);
  };
  const setemailHandler = (e) => {
    setemail(e);
  };
  const setfnameHandler = (e) => {
    setfname(e);
  };
  const setlnameHandler = (e) => {
    setlname(e);
  };
  const setphoneHandler = (e) => {
    setphone(e);
  };
  const handleFetchAllEvents = async () => {
    try {
      const response = await eventApi.fetchEvents();
      if (response.data.success) {
        setEvents(response.data.payload);
      }
    } catch (err) {
      alert("something went wrong");
    }
  };

  const training_register = async () => {
    const values = {
      firstname: fname,
      lastname: lname,
      email: email,
      phone: phone,
      eventInfo: eventInfo,
    };
    // document.activeElement.blur();
    setLoader(true);
    console.log("Values", values);
    // console.log("hdsjhsdjhs");
    // alert("wdssd");
    try {
      if (
        fname &&
        lname &&
        email &&
        phone &&
        Object.keys(eventInfo).length > 0
      ) {
        let resp = await userApi.trainingRegister(values);
        setLoader(false);
        if (resp.data.success) {
          if (window.fbq) {
            console.log("fbq track lead");
            window.fbq("track", "Lead");
          }

          setlname("");
          setfname("");
          setemail("");
          setphone("");
          setEventInfo("");
          navigate(
            `/webinar-thank-you?id=${resp?.data?.payload?.eventInfo._id}`
          );
          setError(false);
          // setSuccesfullyRegistered(true);
          return;
        }
        setError(true);
        setFormMessage(resp.data.message);
      } else {
        setLoader(false);
        setTimeout(() => {
          setShowError(false);
        }, 2000);
        setShowError(true);
        setFormMessage("All fields are required.");
      }
    } catch (err) {
      setLoader(false);
      setTimeout(() => {
        setShowError(true);
      }, 2000);
      setShowError(true);
      setError(true);
      setFormMessage(err.message);
    }
  };

  return (
    <div className="">
      <div style={{ background: "#0d1a21", width: "100%" }}>
        <a
          class=" text-uppercase"
          style={{
            display: "flex",
            paddingTop: "10px",
            justifyItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            onClick={() => navigate("/")}
            class="logo-light"
            src={require("../../../images/PNG-01.png")}
            alt=""
            height="110"
            style={{ cursor: "pointer" }}
            //   width="150"
          />
        </a>
        <div
          class=" text-uppercase "
          style={{
            display: "flex",

            paddingTop: "10px",
            justifyItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2 style={{ fontSize: "36px", color: "#f8971d" }}>
            {" "}
            <strong> FREE LIVE WEBINAR </strong>
          </h2>
          <div
            className=""
            style={{
              display: "flex",
              textAlign: "center",
              width: "90%",
              paddingInline: "59px",
              paddingBottom: "40px",
            }}
          >
            <h1 style={{ fontSize: "35px", color: "#ffffff" }}>
              <strong>
                Discover How To Invest In Private & Public Companies Like The
                Top 1% & Make Huge Returns While Minimizing Risk In Any Economic
                Conditions So
                <br />
                You Can Participate In The Coming Market Shift
                <br />
                That is Getting Ready To Happen!
              </strong>
            </h1>
          </div>
          <div
            className="masterArea"
            style={{
              background: "#000000",
              justifyContent: "space-around",
              display: "flex",
              textAlign: "center",
              alignItems:'center',
              width: "100%",
              padding: "20px",
            }}
          >
            <button
              onClick={openModal}
              className="h_btn"
              style={{
                // padding: "15px",
                height:"10vh",
                paddingLeft:"10px",
                paddingRight:"10px",
                background: "#f8971d",
                border: "none",
                cursor: "pointer",
                outline: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: "2rem",
                  color: "#ffffff",
                  fontWeight: "bold",
                }}
              >
                Register now for your training
              </span>

              <svg
                width="38px"
                height="38px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                id="right-circle"
                class="icon glyph"
                fill="white"
                style={{ marginLeft: "5px" }}
              >
                <path
                  d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm2.71,10.71-3,3a1,1,0,0,1-1.42,0,1,1,0,0,1,0-1.42L12.59,12l-2.3-2.29a1,1,0,0,1,1.42-1.42l3,3A1,1,0,0,1,14.71,12.71Z"
                  // style="fill:#231f20"
                ></path>
              </svg>
            </button>

            <div>
              <h2 style={{ fontSize: "30px", color: "#ffffff" }}>
                <strong>NEW MASTERCLASSES:</strong>
              </h2>
              {events &&
                events.length > 0 &&
                events.map((event) => {
                  return (
                    <>
                    <h2 style={{ fontSize: "25px", color: "#f8971d" }}>
                      <strong>{event.event_date}</strong>
                    </h2>
                    </>
                  );
                })}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              padding: "40px",
              justifyContent: "center",
              background: "#263238",
              paddingTop: "50px",
              paddingBottom: "50px",
              // backgroundColor: "red",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "#263238",

                width: "80%",
              }}
            >
              <div style={{ display: "flex", gap: "40px" }}>
                {/* Mike */}
                <div
                  className=""
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      background: "#EDEDED",
                      padding: "8px",
                      display: "flex",
                      width: "40vw",
                    }}
                  >
                    <img
                      class="logo-light"
                      src={require("../../../images/mike.jpeg")}
                      alt=""
                      style={{ width: "40vw" }}
                      // width={"100%"}
                      // width="150"
                    />
                  </div>
                  <div
                    style={{
                      // display: "flex",
                      padding: "4px",
                      paddingTop: "12px",
                    }}
                  >
                    <h2
                      style={{
                        fontSize: "23px",
                        color: "#ffffff",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <strong>
                        Your Host & Investing Veteran:{" "}
                        <span
                          className="underline"
                          style={{
                            textDecorationLine: "underline",
                            color: "#f8971d",
                          }}
                        >
                          Mike Coval
                        </span>
                      </strong>
                    </h2>
                  </div>
                </div>

                <div
                  style={{
                    width: "80%",
                    // backgroundColor: "yellow",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h2 style={{ fontSize: "28px", color: "#ffffff" }}>
                    Here’s What You’ll Discover In This Breakthrough Training:
                  </h2>
                  <div style={{ padding: "20px" }}>
                    <div
                      className=""
                      style={{
                        display: "flex ",
                        justifyItems: "center",
                        gap: "7px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        style={{
                          width: "50px",
                          height: "45px",
                          color: "#f8971d",
                        }}
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <p style={{ color: "#ffffff", fontSize: "20px" }}>
                        The investing strategy the top 1% of investors follow to
                        get HUGE returns.
                      </p>
                    </div>
                    <div
                      className=""
                      style={{
                        display: "flex ",
                        justifyItems: "center",
                        gap: "7px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        style={{
                          width: "50px",
                          height: "45px",
                          color: "#f8971d",
                        }}
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <p style={{ color: "#ffffff", fontSize: "20px" }}>
                        How to gain REAL profits from investing in public and
                        privately held companies.
                      </p>
                    </div>
                    <div
                      className=""
                      style={{
                        display: "flex ",
                        justifyItems: "center",
                        gap: "7px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        style={{
                          width: "50px",
                          height: "45px",
                          color: "#f8971d",
                        }}
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <p style={{ color: "#ffffff", fontSize: "20px" }}>
                        Ways to spot the next Google 0r Microsoft before it
                        becomes a publicly traded company.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <button
                onClick={openModal}
                className="h_btn"
                style={{
                  // width: "100%",
                  padding: "15px",
                  background: "#f8971d",
                  border: "none",
                  cursor: "pointer",
                  outline: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "2rem",
                    color: "#ffffff",
                    fontWeight: "bold",
                  }}
                >
                  R.S.V.P For The Live Training
                </span>

                <svg
                  width="38px"
                  height="38px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  id="right-circle"
                  class="icon glyph"
                  fill="white"
                  style={{ marginLeft: "5px" }}
                >
                  <path
                    d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm2.71,10.71-3,3a1,1,0,0,1-1.42,0,1,1,0,0,1,0-1.42L12.59,12l-2.3-2.29a1,1,0,0,1,1.42-1.42l3,3A1,1,0,0,1,14.71,12.71Z"
                    // style="fill:#231f20"
                  ></path>
                </svg>
              </button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              background: "#000000",
              paddingTop: "10px",
              paddingBottom: "10px",
              // marginTop: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                background: "#000000",

                padding: "50px",
                width: "85%",
                color: "white",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "15px",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  © 2023 ARD-EDU. All Rights Reserved.
                  <br />
                  Privacy | Terms | Disclaimer
                </p>
              </div>
            </div>
          </div>
          {modal ? (
            <section className="modal1__bg">
              <div
                class="modal-btn"
                onClick={openModal}
                // href="javascript:void(0)"
                data-bs-toggle="modal"
                data-bs-target=".watchvideomodal"
              ></div>
              <div className="modal1__align">
                <div
                  className="widWebinar"
                  style={{
                    // height: "80%",
                    background: "#ffffff",
                    // width: "38%",
                  }}
                  modal={modal}
                >
                  <svg
                    onClick={openModal}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="black"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    width={"40px"}
                    className="cross_btn svgpos"
                    style={{
                      // background: "red",
                      position: "absolute",

                      textAlign: "end",
                      color: "#ffffff",

                      cursor: "pointer",
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>

                  {/* {alertRed ? <div class="modal-alert-msg ">{error}</div> : ""} */}
                  <div
                    className=""
                    style={{
                      padding: "10px",
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        background: "#06080B",
                        color: "#ffffff",
                        paddingTop: "30px",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{ textAlign: "center", paddingInline: "40px" }}
                      >
                        <h5 style={{ fontSize: "14px", marginBottom: "20px" }}>
                          Almost there! Please complete this form and click the
                          button below to gain instant access.{" "}
                        </h5>
                        <ProgressBar />
                        <h2 style={{ fontSize: "22px", marginTop: "20px" }}>
                          Enter your email address below and reserve your seat..
                          it's 100% FREE!
                        </h2>
                        <div
                          style={{ paddingInline: "30px", paddingTop: "20px" }}
                        >
                          {/* {succesfullyRegistered ? (
                            <div
                              className={`alert ${"alert-success"}`}
                              style={{ fontSize: "12px" }}
                            >
                              Confirmation E-mail has been sent
                            </div>
                          ) : (
                            ""
                          )} */}
                          {showError ? (
                            <div
                              className={`alert 
                               alert-danger
                              }`}
                              style={{ fontSize: "12px" }}
                            >
                              {formMessage}
                            </div>
                          ) : (
                            ""
                          )}
                          <div class="form-group">
                            <input
                              value={fname}
                              onChange={(e) => setfnameHandler(e.target.value)}
                              class="form-control"
                              type="text"
                              name="firstname"
                              placeholder="First Name**"
                              style={{ paddingInline: "0px", padding: "12px" }}
                            />
                          </div>
                          <div class="form-group">
                            <input
                              value={lname}
                              class="form-control"
                              onChange={(e) => setlnameHandler(e.target.value)}
                              type="text"
                              name="password"
                              placeholder="Last Name**"
                              style={{ paddingInline: "0px", padding: "12px" }}
                            />
                          </div>

                          <div class="form-group">
                            <input
                              value={email}
                              autoComplete="new-password"
                              class="form-control"
                              type="email"
                              name="email"
                              placeholder="Email Address**"
                              style={{ paddingInline: "0px", padding: "12px" }}
                              onChange={(e) => setemailHandler(e.target.value)}
                            />
                          </div>

                          <div class="form-group">
                            <PhoneInput
                              international
                              class="form-control px-2 py-2"
                              placeholder="Phone number**"
                              defaultCountry="US"
                              value={phone}
                              onChange={(value) => setphoneHandler(value)}
                              countrySelectProps={{
                                unicodeFlags: true,
                                style: {
                                  // Customize the SVG color here
                                  color: "#FF0000",
                                  background: "yellow",
                                },
                              }}
                              // countrySelectProps={{ unicodeFlags: true }}

                              // value={value}
                              // onChange={setValue}
                            />
                          </div>

                          <div class="form-group">
                            {events &&
                              events.length > 0 &&
                              events.map((event, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      fontSize: "12px",
                                    }}
                                    onClick={() => {
                                      setEventInfo(event);
                                    }}
                                  >
                                    <div>
                                      <input
                                        onChange={() => {
                                          setEventInfo(event);
                                        }}
                                        checked={eventInfo._id == event._id}
                                        type="radio"
                                        id="myRadio"
                                        name="myRadio"
                                        style={{
                                          width: "10px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <div className="mt-1">
                                      <label
                                        className="ml-2"
                                        id="myRadio"
                                        name="myRadio"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <span>
                                          {event.event_date} <br />
                                          {/* <b> {moment(event.event_date).format("MM/DD")}</b> */}
                                        </span>
                                        <span className="">
                                          <b>{event.event_title}</b>
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>

                          <button
                            onClick={training_register}
                            className="h_btn"
                            style={{
                              width: "100%",
                              padding: "10px",
                              background: "#f8971d",
                              border: "none",
                              cursor: "pointer",
                              outline: "none",
                            }}
                          >
                            {loader ? (
                              <i className="fa fa-circle-o-notch fa-spin mr-3"></i>
                            ) : (
                              ""
                            )}
                            <span
                              style={{
                                fontSize: "15px",
                                color: "#ffffff",
                                fontWeight: "bold",
                              }}
                            >
                              R.S.V.P For The Live Training
                            </span>
                          </button>
                          <h5
                            style={{
                              fontSize: "14px",
                              marginTop: "30px",
                              marginBottom: "20px",
                            }}
                          >
                            Your Information is 100% Secure And Will Never Be
                            Shared With Anyone
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
          {/* <!-- END MODAL --> */}
        </div>
      </div>
    </div>
  );
};
export default Webinar;
