import React from "react";

const Footer = () => {
  return (
    <div>
      {" "}
      <div class="footer-alt1  p-3">
        <div class="d-flex flex-column min-vh-100">
          <div class="row">
            <div class="col-lg-12">
              <div class="text-center">
                <p class="mb-0 fs-15 text-white">
                  © ARD-EDU, Inc. 2023 | All Rights Reserved | Design and
                  Developed by{" "}
                  <a
                    href="https://dimensionalsys.com/"
                    target="_blank"
                    className="text-white"
                  >
                    Dimensional Sys, Inc
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
