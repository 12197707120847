import React from "react";

const Footer = () => {
  return (
    <div class="footer-alt pt-4 pb-4">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 fs-15 text-white">
                © ARD-EDU, Inc. 2023 | All Rights Reserved | Design and
                Developed by{" "}
                <a
                  href="https://dimensionalsys.com/"
                  target="_blank"
                  className="text-white"
                >
                  Dimensional Sys, Inc
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
