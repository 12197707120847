import "../../../css/style.css";
import "../../../css/materialdesignicons.min.css";
import "../../../css/swiper-bundle.min.css";
import "../../../scss/_menu.scss";
import "../../../scss/_pricing.scss";
import "../../../css/modal.css";

const MultiSteps = () => {
  return (
    // <div  className="">
    //   <div class="row justify-content-center">
    //     <div class="col-lg-12">
    //       <div class="text-center mb-5">
    //         <h3 class="heading mb-3">
    //           <span class="text-primary">Four </span>Simple Steps
    //         </h3>
    //       </div>
    //     </div>
    //   </div>
    //   <div style={{ display: "flex", width: "", justifyContent: "center" }}>
    //     <div class="row" >
    //       <div class="col">
    //         <div class="step ml-4">
    //           <h4>Step 1</h4>
    //           <p>Register for Website Access</p>
    //         </div>
    //       </div>
    //       <div class="col">
    //         <div class="step">
    //           <h4>Step 2</h4>
    //           <p>Watch the Cash Flow Quick Start Video</p>
    //         </div>
    //       </div>
    //       <div class="col">
    //         <div class="step">
    //           <h4>Step 3</h4>
    //           <p>Watch the “Website Tour”</p>
    //         </div>
    //       </div>
    //       <div class="col">
    //         <div class="step">
    //           <h4>Step 4</h4>
    //           <p>
    //             “Check your email address for emails from @ardedu-email.com for
    //             your welcome email and registration to our Capital Conference
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    //     <div className="">
    //   <div className="row justify-content-center">
    //     <div className="col-lg-12">
    //       <div className="text-center mb-5">
    //         <h3 className="heading mb-3">
    //           <span className="text-primary">Four </span>Simple Steps
    //         </h3>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="row justify-content-center">
    //     <div className="col-sm">
    //       <div className="step ml-4">
    //         <h4>Step 1</h4>
    //         <p>Register for Website Access</p>
    //       </div>
    //     </div>
    //     <div className="col-sm">
    //       <div className="step">
    //         <h4>Step 2</h4>
    //         <p>Watch the Cash Flow Quick Start Video</p>
    //       </div>
    //     </div>
    //     <div className="col-sm">
    //       <div className="step">
    //         <h4>Step 3</h4>
    //         <p>Watch the "Website Tour"</p>
    //       </div>
    //     </div>
    //     <div className="col-sm">
    //       <div className="step">
    //         <h4>Step 4</h4>
    //         <p>
    //           Check your email address for emails from @ardedu-email.com for your
    //           welcome email and registration to our Capital Conference
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div style={{ overflowX: 'hidden' }} className="text-center container">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="text-center mb-5">
            <h3 className="heading mb-3">
              <span className="text-primary">Four </span>Simple Steps
            </h3>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-sm">
          <div className="step">
            <h4>Step 1</h4>
            <p>Register for Website Access</p>
          </div>
        </div>
        <div className="col-sm">
          <div className="step">
            <h4>Step 2</h4>
            <p>Watch the Cash Flow Quick Start Video</p>
          </div>
        </div>
        <div className="col-sm">
          <div className="step">
            <h4>Step 3</h4>
            <p>Watch the "Website Tour"</p>
          </div>
        </div>
        <div className="col-sm">
          <div className="step">
            <h4>Step 4</h4>
            <p>
              Check your email address for emails from @ardedu-email.com for
              your welcome email and registration to our Capital Conference
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MultiSteps;
