import axios from "axios";
import { useNavigate } from "react-router-dom";

// Add a request interceptor

axios.interceptors.request.use(
  (req) => {
    let headers = {
      ...req.headers,
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    if (headers) {
      req.headers = headers;
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

export default axios;
