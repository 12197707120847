import * as yup from "yup";

export const SignupValidationSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  password: yup.string().required("Password is required"),
  confirmpassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const SigninValidationSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  password: yup.string().required("Password is required"),
});

export const ForgetPasswordValidationSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
});

export const ResetPasswordValidationSchema = yup.object().shape({
  password: yup.string().required("Password is required"),
  confirmpassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const SignupDetailValidationSchema = yup.object().shape({
  firstname: yup.string().required("Firstname is required"),
  lastname: yup.string().required("Lastname is required"),
  plan: yup.string().required("Plan is required"),
  price: yup.string().required("Price is required"),
  // token: yup.string().required('Password is required'),
  cardToken: yup.string().required("Card number is required"),
});
