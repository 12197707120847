export const marketJson = {
  "Automotive and Boat": [
    {
      key: "Auto Repair and Service Shops",
      value: 2.74,
    },
    {
      key: "Car Dealerships",
      value: 3.04,
    },
    {
      key: "Car Washes",
      value: 4.87,
    },
    {
      key: "Equipment Rental and Dealers",
      value: 2.9,
    },
    {
      key: "Gas Stations",
      value: 3.31,
    },
    {
      key: "Junk and Salvage Yards",
      value: 3.06,
    },
    {
      key: "Marine/Boat Service and Dealers",
      value: 2.85,
    },
    {
      key: "Towing Companies",
      value: 3.08,
    },
    {
      key: "Truck Stops",
      value: 3.28,
    },
  ],
  "Beauty and Personal Care": [
    {
      key: "Hair Salons and Barber Shops",
      value: 1.85,
    },
    {
      key: "Massage Businesses",
      value: 2.18,
    },
    {
      key: "Nail Salons",
      value: 1.6,
    },
    {
      key: "Spas",
      value: 2.19,
    },
    {
      key: "Tanning Salons",
      value: 2.06,
    },
  ],
  "Building and Construction": [
    {
      key: "Building Material and Hardware Stores",
      value: 2.94,
    },
    {
      key: "Concrete Businesses",
      value: 2.54,
    },
    {
      key: "Electrical and Mechanical Contracting Businesses",
      value: 2.44,
    },
    {
      key: "Heavy Construction Businesses",
      value: 2.5,
    },
    {
      key: "HVAC Businesses",
      value: 2.62,
    },
    {
      key: "Plumbing Businesses",
      value: 2.41,
    },
  ],
  "Communication and Media": [
    {
      key: "Magazines and Newspapers",
      value: 2.15,
    },
    {
      key: "Production Companies",
      value: 2.5,
    },
  ],
  "Education and Children": [
    {
      key: "Day Care and Child Care Centers",
      value: 3.34,
    },
    {
      key: "Preschools",
      value: 3.54,
    },
    {
      key: "Schools",
      value: 2.52,
    },
  ],
  "Entertainment and Recreation": [
    {
      key: "Art Galleries",
      value: 2.04,
    },
    {
      key: "Banquet Halls",
      value: 3.18,
    },
    {
      key: "Bowling Alleys",
      value: 3.57,
    },
    {
      key: "Casinos",
      value: 2.32,
    },
    {
      key: "Golf Courses and Services",
      value: 2.49,
    },
    {
      key: "Marinas and Fishing",
      value: 2.8,
    },
    {
      key: "Nightclubs and Theaters",
      value: 2.66,
    },
  ],
  "Financial Services": [
    {
      key: "Accounting and Tax Practices",
      value: 2.12,
    },
    {
      key: "Banking and Loan Businesses",
      value: 2.43,
    },
    {
      key: "Check Cashing Businesses",
      value: 1.68,
    },
    {
      key: "Financial Services Businesses",
      value: 2.79,
    },
    {
      key: "Insurance Agencies",
      value: 3.06,
    },
  ],
  "Food and Restaurants": [
    {
      key: "Bakeries",
      value: 2.14,
    },
    {
      key: "Bars, Pubs and Taverns",
      value: 2.56,
    },
    {
      key: "Breweries",
      value: 3.72,
    },
    {
      key: "Coffee Shops and Cafes",
      value: 2.03,
    },
    {
      key: "Donut Shops",
      value: 2.3,
    },
    {
      key: "Food Trucks",
      value: 1.64,
    },
    {
      key: "Ice Cream and Frozen Yogurt Shops",
      value: 2.32,
    },
    {
      key: "Juice Bars",
      value: 2.02,
    },
    {
      key: "Restaurants",
      value: 2.06,
    },
  ],
  "Health Care and Fitness": [
    {
      key: "Assisted Living and Nursing Homes",
      value: 4.24,
    },
    {
      key: "Dance, Pilates, and Yoga Studios",
      value: 1.96,
    },
    {
      key: "Dental Practices",
      value: 2.3,
    },
    {
      key: "Gyms and Fitness Centers",
      value: 2.35,
    },
    {
      key: "Home Health Care Businesses",
      value: 2.89,
    },
    {
      key: "Medical Practices",
      value: 2.13,
    },
  ],
  "Manufacturing": [
    {
      key: "Auto, Boat and Aircraft Manufacturers",
      value: 3.27,
    },
    {
      key: "Chemical and Related Product Manufacturers",
      value: 3.47,
    },
    {
      key: "Clothing and Fabric Manufacturers",
      value: 2.42,
    },
    {
      key: "Electronic and Electrical Equipment Manufacturers",
      value: 3.05,
    },
    {
      key: "Food and Related Product Manufacturers",
      value: 2.78,
    },
    {
      key: "Furniture and Fixture Manufacturers",
      value: 2.78,
    },
    {
      key: "Glass, Stone, and Concrete Manufacturers",
      value: 2.91,
    },
    {
      key: "Industrial and Commercial Machinery Manufacturers",
      value: 3.84,
    },
    {
      key: "Lumber and Wood Products Manufacturers",
      value: 3.09,
    },
    {
      key: "Machine Shops and Tool Manufacturers",
      value: 3.23,
    },
    {
      key: "Medical Device and Product Manufacturers",
      value: 2.66,
    },
    {
      key: "Metal Product Manufacturers",
      value: 3.05,
    },
    {
      key: "Packaging Businesses",
      value: 3.02,
    },
    {
      key: "Paper Manufacturers and Printing Businesses",
      value: 2.45,
    },
    {
      key: "Rubber and Plastic Products Manufacturers",
      value: 3.22,
    },
    {
      key: "Sign Manufacturers and Businesses",
      value: 2.43,
    },
  ],
  "Online and Technology": [
    {
      key: "Cell Phone and Computer Repair and Service Businesses",
      value: 2.01,
    },
    {
      key: "Graphic and Web Design Businesses",
      value: 2.62,
    },
    {
      key: "IT and Software Service Businesses",
      value: 2.93,
    },
    {
      key: "Software and App Companies",
      value: 3.26,
    },
    {
      key: "Websites and Ecommerce Businesses",
      value: 3.33,
    },
  ],
  "Pet Services": [
    {
      key: "Dog Daycare and Boarding Businesses",
      value: 3.1,
    },
    {
      key: "Pet Grooming Businesses",
      value: 1.96,
    },
    {
      key: "Pet Stores and Supply Businesses",
      value: 2.28,
    },
  ],
  "Retail": [
    {
      key: "Bike Shops",
      value: 2.74,
    },
    {
      key: "Clothing and Accessory Stores",
      value: 2.17,
    },
    {
      key: "Convenience Stores",
      value: 2.29,
    },
    {
      key: "Dollar Stores",
      value: 1.47,
    },
    {
      key: "Flower Shops",
      value: 1.95,
    },
    {
      key: "Furniture and Furnishings Stores",
      value: 1.94,
    },
    {
      key: "Grocery Stores and Supermarkets",
      value: 2.65,
    },
    {
      key: "Health Food and Nutrition Businesses",
      value: 2.03,
    },
    {
      key: "Jewelry Stores",
      value: 1.93,
    },
    {
      key: "Liquor Stores",
      value: 2.98,
    },
    {
      key: "Nursery and Garden Centers",
      value: 2.68,
    },
    {
      key: "Pawn Shops",
      value: 2.15,
    },
    {
      key: "Pharmacies",
      value: 2.44,
    },
    {
      key: "Smoke Shops",
      value: 1.71,
    },
    {
      key: "Vending Machine Businesses",
      value: 2.34,
    },
  ],
  "Service Businesses": [
    {
      key: "Architecture and Engineering Firms",
      value: 2.62,
    },
    {
      key: "Catering Companies",
      value: 2.04,
    },
    {
      key: "Cleaning Businesses",
      value: 2.09,
    },
    {
      key: "Commercial Laundry Businesses",
      value: 2.8,
    },
    {
      key: "Dry Cleaners",
      value: 2.08,
    },
    {
      key: "Funeral Homes",
      value: 4.76,
    },
    {
      key: "Landscaping and Yard Service Businesses",
      value: 2.27,
    },
    {
      key: "Laundromats and Coin Laundry Businesses",
      value: 3.25,
    },
    {
      key: "Legal Services and Law Firms",
      value: 1.94,
    },
    {
      key: "Locksmith Businesses",
      value: 2.04,
    },
    {
      key: "Medical Billing Businesses",
      value: 3.41,
    },
    {
      key: "Pest Control Businesses",
      value: 2.34,
    },
    {
      key: "Property Management Businesses",
      value: 2.58,
    },
    {
      key: "Security Businesses",
      value: 2.7,
    },
    {
      key: "Staffing Agencies",
      value: 2.73,
    },
    {
      key: "Waste Management and Recycling Businesses",
      value: 3.18,
    },
  ],
  "Transportation and Storage": [
    {
      key: "Delivery, Freight and Service Routes",
      value: 1.93,
    },
    {
      key: "Limo and Passenger Transportation Businesses",
      value: 2.49,
    },
    {
      key: "Moving and Shipping Businesses",
      value: 2.61,
    },
    {
      key: "Storage Facilities and Warehouses",
      value: 2.82,
    },
    {
      key: "Trucking Companies",
      value: 2.99,
    },
  ],
  "Wholesale and Distributors": [
    {
      key: "Durable Goods Wholesalers and Distributors",
      value: 2.89,
    },
    {
      key: "Nondurable Goods Wholesalers and Distributors",
      value: 2.73,
    },
  ],
};
export const trendMap = {
  "Declining Revenues - 20% year over year, no turnaround yet": {
    0: -9.09,
    1: -9.75,
    2: -10.25,
    3: -11.11,
  },
  "Declining Revenues -20% year over year, turnaround started": {
    0: -4.55,
    1: -4.9,
    2: -5.13,
    3: -5.56,
  },
  "Steady Revenue, sustainable": {
    0: 0,
    1: -4.54,
    2: -4.89,
    3: -5.12,
  },
  "Increasing Revenues +20%year over year, sustainable": {
    0: 4.55,
    1: 4.9,
    2: 5.13,
    3: 5.56,
  },
  "Increasing Revenues +20%year over year, non-sustainable": {
    0: 9.09,
    1: 9.75,
    2: 10.25,
    3: 11.11,
  },
};
