import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminNav from "../../../components/navbar/adminNav";
import adminApi from "../../../services/adminApi";
import "../../../css/formSignin.css";
import * as moment from "moment";
const EditInfo = () => {
  useEffect(() => {
    const pathname = window.location.pathname; // "/course-content/123"
    const pathParts = pathname.split("/"); // ["", "course-content", "123"]
    const id = pathParts[2];
    console.log(id);
    user_detail(id);
  }, []);
  const navigate = useNavigate();
  const [data, setdata] = useState();
  const [fname, setfname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [lname, setlname] = useState("");
  const [phone, setphone] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [street, setStreet] = useState("");
  const [card, setCard] = useState("");
  const [expiry, setexpiry] = useState("");
  const [cvc, setcvc] = useState("");
  const [courseExp, setcourse] = useState("");
  const [loader, setLoader] = useState(false);

  const user_detail = async (id) => {
    try {
      const resp = await adminApi.findUser(id);
      if (resp.data.success == true) {
        setdata(resp.data.payload);
        setfname(resp.data.payload.user.firstname);
        setlname(resp.data.payload.user.lastname);
        setphone(resp.data.payload.user.phone);
        setEmail(resp.data.payload.user.email);
        setZip(resp.data.payload.user.location[0].zip);
        setCity(resp.data.payload.user.location[0].city);
        setState(resp.data.payload.user.location[0].state);
        setStreet(resp.data.payload.user.location[0].street);
        setCard(resp.data.payload.user.cardInfo[0].card);
        setexpiry(
          resp.data.payload.user.cardInfo[0].expiry.slice(0, 2) +
            "-" +
            resp.data.payload.user.cardInfo[0].expiry.slice(2)
        );
        // console.log(
        //   "expiry",
        //   resp.data.payload.user.cardInfo[0].expiry.slice(0, 2)
        // );
        setcvc(resp.data.payload.user.cardInfo[0].cvv);
        // console.log("course",moment(resp.data.payload.user.course_expiry).format("YYYY-MM-DD"))
        setcourse(moment(resp.data.payload.user.course_expiry).format("YYYY-MM-DD"));
      }
      // console.log("resp", resp);
    } catch (err) {
      console.log(err);
    }
  };

  const updateUserDetail = async () => {
    const pathname = window.location.pathname; // "/course-content/123"
    const pathParts = pathname.split("/"); // ["", "course-content", "123"]
    const id = pathParts[2];
    // console.log("expiry", new Date(courseExp) ,moment(courseExp));
    const value = {
      id: id,
      firstname: fname,
      lastname: lname,
      phone: phone,
      password: password,
      zip: zip,
      city: city,
      state: state,
      street: street,
      card: card,
      cvc: cvc,
      expiry: expiry.replaceAll("-", ""),
      course_expiry: courseExp,
      //; expiry,
    };
    setLoader(true);
    try {
      const response = await adminApi.updateUserDetail(value);
      if (response.data.success == true) {
        setLoader(false);
        navigate("/user-mangement");
      }
    } catch (err) {}
  };

  const setfnameHandler = (e) => {
    setfname(e);
  };
  const setpasswordHandler = (e) => {
    setpassword(e);
  };
  const setlnameHandler = (e) => {
    setlname(e);
  };
  const setphoneHandler = (e) => {
    setphone(e);
  };
  const setzipHandler = (e) => {
    setZip(e);
  };
  const setcityHandler = (e) => {
    setCity(e);
  };
  const setstateHandler = (e) => {
    setState(e);
  };
  const setstreetHandler = (e) => {
    setStreet(e);
  };
  const setcardHandler = (e) => {
    setCard(e);
  };
  const setexpiryHandler = (e) => {
    // console.log("ee", a);
    setexpiry(e);
  };
  const setcvcHandler = (e) => {
    setcvc(e);
  };
  const setcourseHandler = (e) => {
    setcourse(e);
  };

  return (
    <div>
      <AdminNav />
      <div className=" ">
        <div className="adminUserInfo1">
          <div className="">
            <div className="mainLayoutUser1 ">
              <div className="mainUserInfo">
                <h5>UserInfo</h5>
                <div class="form-group">
                  <label className="UserformLabel">First Name</label>
                  <input
                    value={fname}
                    onChange={(e) => setfnameHandler(e.target.value)}
                    class="form-control UserformInput"
                    type="text"
                    name="firstname"
                    placeholder="First Name"
                  />
                </div>
                <div class="form-group">
                  <label className="UserformLabel">Last Name</label>
                  <input
                    value={lname}
                    class="form-control UserformInput"
                    onChange={(e) => setlnameHandler(e.target.value)}
                    type="text"
                    name="password"
                    placeholder="Last Name"
                  />
                </div>
                <div class="form-group">
                  <label className="UserformLabel">Email</label>
                  <input
                    readOnly
                    value={email}
                    class="form-control UserformInput"
                    type="text"
                    name="email"
                    placeholder="Email"
                  />
                </div>
                <div class="form-group">
                  <label className="UserformLabel">Password (Optional)</label>
                  <input
                    value={password}
                    class="form-control UserformInput"
                    onChange={(e) => setpasswordHandler(e.target.value)}
                    type="text"
                    name="password"
                    placeholder="Password"
                  />
                </div>
                <div class="form-group">
                  <label className="UserformLabel">Phone</label>
                  <input
                    value={phone}
                    class="form-control UserformInput"
                    onChange={(e) => setphoneHandler(e.target.value)}
                    type="text"
                    name="password-repeat"
                    placeholder="Phone"
                  />
                </div>
                <div class="form-group">
                  <label className="UserformLabel">Zip</label>
                  <input
                    value={zip}
                    class="form-control UserformInput"
                    onChange={(e) => setzipHandler(e.target.value)}
                    type="text"
                    name="password-repeat"
                    placeholder="Zip"
                  />
                </div>
                <div class="form-group">
                  <label className="UserformLabel">City</label>
                  <input
                    value={city}
                    class="form-control UserformInput"
                    onChange={(e) => setcityHandler(e.target.value)}
                    type="text"
                    name="password-repeat"
                    placeholder="City"
                  />
                </div>
                <div class="form-group">
                  <label className="UserformLabel">State</label>
                  <input
                    value={state}
                    class="form-control UserformInput"
                    onChange={(e) => setstateHandler(e.target.value)}
                    type="text"
                    name="password-repeat"
                    placeholder="State"
                  />
                </div>
                <div class="form-group">
                  <label className="UserformLabel">Street</label>
                  <input
                    value={street}
                    class="form-control UserformInput"
                    onChange={(e) => setstreetHandler(e.target.value)}
                    type="text"
                    name="password-repeat"
                    placeholder="Street"
                  />
                </div>
              </div>
              <div className="mainCardInfo">
                <h5>Card-Info</h5>
                <div class="form-group">
                  <label className="UserformLabel">Card</label>
                  <input
                    value={card}
                    class="form-control UserformInput"
                    onChange={(e) => setcardHandler(e.target.value)}
                    type="text"
                    name="password-repeat"
                    placeholder="Card"
                  />
                </div>
                <div class="form-group">
                  <label className="UserformLabel">Cvv</label>
                  <input
                    value={cvc}
                    class="form-control UserformInput"
                    onChange={(e) => setcvcHandler(e.target.value)}
                    type="text"
                    name="CVC"
                    placeholder="CVC"
                  />
                </div>
                <div class="form-group">
                  <label className="UserformLabel">Expiry</label>
                  <input
                    value={expiry}
                    class="form-control UserformInput"
                    onChange={(e) => setexpiryHandler(e.target.value)}
                    type="text"
                    name="password-repeat"
                    placeholder="Expiry"
                  />
                </div>
                <h5>Subscription-Info</h5>
                <div class="form-group">
                  <label className="UserformLabel">Course Expiry</label>
                  {/* <input
                    value={courseExp}
                    class="form-control UserformInput"
                    onChange={(e) => setcourseHandler(e.target.value)}
                    type="text"
                    name="password-repeat"
                    placeholder="Expiry"
                  /> */}
                  <input
                    class="form-control UserformInput"
                    onChange={(e) => setcourseHandler(e.target.value)}
                    value={courseExp}
                    type="date"
                    id="birthday"
                    name="birthday"
                  />
                  {/* <div className="mt-1">
                    <p>
                      Date: {courseExp.split(" ")[1]}-{courseExp.split(" ")[2]}-
                      {courseExp.split(" ")[3]}
                    </p>
                    <p>
                      Time: {courseExp.split(" ")[4]} {courseExp.split(" ")[5]}
                    </p>
                  </div> */}
                </div>
                <div className="modal1__btn_layout">
                  <button
                    className="btn update_btn_content modal1__btn "
                    onClick={() => {
                      updateUserDetail();
                    }}
                  >
                    {loader ? (
                      <i class="fa fa-circle-o-notch fa-spin mr-4"></i>
                    ) : (
                      ""
                    )}
                    Update
                  </button>
                  <button
                    className="btn btn-primary modal1__btn "
                    onClick={() => {
                      navigate("/user-mangement");
                    }}
                  >
                    {/* {loader ? (
                        <i class="fa fa-circle-o-notch fa-spin mr-4"></i>
                      ) : (
                        ""
                      )} */}
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditInfo;
