import logo from "./logo.svg";
import "./App.css";
import Homepage from "./pages/homepage";
import Navigator from "./router";

function App() {
  return <Navigator />;
}

export default App;
