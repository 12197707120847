import React from "react";
import Navbar from "../../components/navbar/navbar";
import { useNavigate, Link } from "react-router-dom";
import "../../css/404.css";
import AuthNav from "../../components/navbar/authNav";
const Notfound = () => {
  const navigate = useNavigate();
  const navButton = () => {
    if (localStorage.getItem("Admin")) {
      navigate("/admin-course");
    } else {
      navigate("/course");
    }
  };
  return (
    <div>
      {/* <AuthNav /> */}
      {/* <Navbar /> */}
      <div class=" ">
        <div class="row mt-5 mainlayout p-5">
          <div class="col-md-6  p-5">
            <h1 className="">404</h1>
            <h2>UH OH! You're lost.</h2>
            <p>The page you are looking for does not exist.</p>
            {/* <button
              class="btn-primary btn"
              onClick={() => {
                navButton();
              }}
            >
              Back to Courses
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Notfound;
