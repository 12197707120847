import React from "react";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();
  const signout = () => {
    localStorage.clear();
    navigate("/sign-in");
  };
  return (
    <div>
      {" "}
      <nav
        class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky"
        id="navbar"
      >
        <div class="container">
          <a class="navbar-brand text-uppercase">
            <img
              class="logo-light"
              src={require("../../images/PNG-01.png")}
              alt=""
              height="85"
            />
          </a>

          <div class="" id="navbarCollapse">
            <p class="text-primary signout" onClick={signout}>
              Sign Out
            </p>
            {/* <!-- Button trigger modal --> */}

            {/* <!-- Nav btn --> */}
          </div>
        </div>
        {/* <!-- End container --> */}
      </nav>
    </div>
  );
};
export default Navbar;
