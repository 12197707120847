import "../../../css/style.css";
import "../../../css/materialdesignicons.min.css";
import "../../../css/swiper-bundle.min.css";
import "../../../scss/_menu.scss";
import "../../../scss/_pricing.scss";
import "../../../css/modal.css";
const Testimonials = () => {
  return (
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-12">
          <div class="text-center ">
            <h3 class="heading">
              <span class="text-primary">STUDENTS </span>TESTIMONIALS
            </h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="swiper mySwiper1 my-5">
            <div class="flex">
              <div class=" client-box card mb-6">
                <div class="client-content card-body p-0">
                  <div class="quote-img">
                    <img
                      src={require("../../../images/quote.png")}
                      alt="quote"
                      class="img-fluid"
                    />
                  </div>
                  <p class="text-dark mb-0 fs-5 lh-base">
                    To be honest, I was skeptical after attending a different
                    class but this class was different. We could see that in the
                    first 30 minutes.
                    <br />
                    Don't put this off. The knowledge that was shared has
                    a;ready started to pay off.
                  </p>
                </div>

                <div class="d-flex align-items-center mt-4 pt-3">
                  <div class="ms-2">
                    <h5 class="mb-0">DEBBIE ST. GEORGE</h5>
                    <p class="text-muted mb-0">
                      Best Part of the Class? "The Invest-Edu team."
                    </p>
                  </div>
                </div>
              </div>
              <div class=" client-box card  ">
                <div class="client-content card-body p-0">
                <div class="quote-img">
                    <img
                      src={require("../../../images/quote.png")}
                      alt="quote"
                      class="img-fluid"
                    />
                  </div>
                  <p class="text-dark mb-0 fs-5 lh-base">
                    This class was hands down the best class I've ever
                    attended!, I was expecting just some fluff, but they taught
                    us real usable strategies for 3 straight days. Be prepared
                    to take lots of notes and get set for some excitement.
                  </p>
                </div>
                {/* <!--end client-content--> */}
                <div class="d-flex align-items-center mt-4 pt-3">
                  <div class="ms-2">
                    <h5 class="mb-0">TIM BONADEAU</h5>
                    <p class="text-muted mb-0">
                      Best Part of the Class? "The live trading."
                    </p>
                  </div>
                </div>
              </div>
              <div class=" client-box card  ">
                <div class="client-content card-body p-0">
                  <div class="quote-img">
                    <img
                      src={require("../../../images/quote.png")}
                      alt="quote"
                      class="img-fluid"
                    />
                  </div>
                  <p class="text-dark mb-0 fs-5 lh-base">
                    Thank you, thank you, thank you. My husband and I have been
                    searching for something that we could do to supplement our
                    income without buying a business or spending months to
                    trying to rehab. Our lives have changed and its all because
                    of you and your caring team.
                  </p>
                </div>
                {/* <!--end client-content--> */}
                <div class="d-flex align-items-center mt-4 pt-3">
                  <div class="ms-2">
                    <h5 class="mb-0">ELIZABETH ANDERSON</h5>
                    <p class="text-muted mb-0">
                      Best Part of the Class? "We can see our future again."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Testimonials;
