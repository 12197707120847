import { marketJson } from "../../../api/jsonData";
import CurrencyInput from "react-currency-input-field";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
const SecondStep = ({ earnings, handleChangeMultipleEarning }) => {
  return (
    <div>
      <Tooltip
        place="bottom"
        variant="error"
        id="my-tooltip"
        style={{
          position: "absolute",
          zIndex: 999,
          maxWidth: "400px",
          backgroundColor: "#0F4369",
        }}
      />

      <table class="table">
        <tbody>
          <tr>
            <th scope="row">
              <span
                data-tooltip-content="The specific line of work the business you are interested in investing in ties to."
                data-tooltip-id="my-tooltip"
              >
                Industry
                <i style={{ color: "gray" }} class="mdi mdi-information"></i>
              </span>
            </th>
            <td>
              <span class="mr-2">$</span>
              <select
                value={earnings.market_multiple_earning}
                name="market_multiple_earning"
                onChange={
                  (e) => {
                    const { value, name } = e.target;
                    handleChangeMultipleEarning({ value, name });
                  }
                }
                className=""
              >
                <option value="">Select an option</option>
                {Object.keys(marketJson).map((item) => (
                <optgroup label={item}>
                  {marketJson[item].map((item) => (
                    <option value={item.value}>{item.key}</option>
                  ))}
                </optgroup>
                ))}
              </select>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th scope="row">
              <span
                data-tooltip-content="The yearly wage the manager or owner receives."
                data-tooltip-id="my-tooltip"
              >
                Owner Salary
                <i style={{ color: "gray" }} class="mdi mdi-information"></i>
              </span>
            </th>
            <td>
              <span class="mr-2">$</span>

              <CurrencyInput
                name="owner_salary"
                id="owner_salary"
                placeholder="Owner Salary"
                defaultValue={
                  earnings.owner_salary === 0 ? "" : earnings.owner_salary
                }
                decimalsLimit={5}
                onValueChange={(value, name) =>
                  handleChangeMultipleEarning({ value, name })
                }
              />
            </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default SecondStep;
