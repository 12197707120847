import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
const AdminNav = () => {
  const navigate = useNavigate();
  const [nav, setnav] = useState(false);
  const [navRes, setNav] = useState(false);
  const signout = () => {
    localStorage.clear();
    navigate("/admin-sign-in");
  };
  const navShow = () => {
    setNav(!navRes);
    // console.log("nav", navRes);
  };

  return (
    <nav
      class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky"
      id="navbar"
    >
      <div class="container">
        {/* <!-- LOGO --> */}
        <a class=" text-uppercase">
          <img
            class="logo-light"
            src={require("../../images/PNG-01.png")}
            alt=""
            height="85"
            //   width="150"
          />
        </a>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="true"
          aria-label="Toggle navigation"
          onClick={() => {
            navShow();
          }}
        >
          <span class="mdi mdi-menu"></span>
          {/* <!-- toggle button --> */}
        </button>

        <div
          class={`collapse navbar-collapse  ${navRes ? "show  " : ""}`}
          id="navbarCollapse"
        >
          <ul
            class={`navbar-nav mx-auto adminNavList    ${
              navRes ? "" : "nav-align-b"
            }`}
            id="navbar-navlist"
          >
            <li class="nav-item">
              <a
                class="nav-li"
                onClick={() => {
                  navigate("/admin-course-mangement");
                }}
                style={{
                  color: "white",
                }}
              >
                Courses
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link adminlist admintitle adminlistNav"
                onClick={() => {
                  navigate("/user-mangement");
                }}
              >
                Registrations
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link adminlist admintitle adminlistNav"
                onClick={() => {
                  navigate("/event-mangement");
                }}
              >
                Events
              </a>
            </li>
          </ul>
          {/* <!-- Button trigger modal --> */}

          {/* <!-- Nav btn --> */}
          <p class="text-primary sign-in" onClick={signout}>
            Sign out
          </p>
        </div>
      </div>
      {/* <!-- End container --> */}
    </nav>
  );
};
export default AdminNav;
