import axios from "../axios";

import {
  fetch_events,
  delete_event,
  update_event,
  create_event,
  find_event,
} from "../api/apiRoutes";

export default {
  fetchEvents: async () => {
    const reponse = await axios.get(fetch_events);
    return reponse;
  },
  deleteEvent: async (id) => {
    const response = await axios.get(`${delete_event}/${id}`);
    return response;
  },
  updateEvent: async (payload) => {
    const response = await axios.post(update_event, payload);
    return response;
  },
  createEvent: async (payload) => {
    const response = await axios.post(create_event, payload);
    return response;
  },
  findEvent: async (payload) => {
    const response = await axios.get(`${find_event}/${payload}`);
    return response;
  },
};
