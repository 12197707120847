import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import coursesApi from "../../../services/coursesApi";
import { useParams } from "react-router-dom";
import Navbar from "../../../components/navbar/navbar";
import Footer from "../../../components/footer";
const Course = () => {
  const navigate = useNavigate();
  const [data, setdata] = useState();
  useEffect(() => {
    coursesData();
  }, []);

  const coursesData = async () => {
    try {
      const resp = await coursesApi.getCoursesData();

      if (resp.data.success == true) {
        // console.log("Courses", resp.data.payload);
        setdata(resp.data.payload);
        console.log("data", data);
      } else {
      }
    } catch (err) {}
  };

  const getParams = (data) => {
    console.log("params", data);
    navigate(`/course-content/${data.course_path}`);
  };
  return (
    <div>
      <Navbar />
      <section class="section services" id="pricing">
        <div class="container mt-5">
          <div class="row g-4">
            <div class="col-lg-12 mt-3">
              <div class="text-center mb-5">
                <h3 class="heading">
                  Exclusive <span class="text-primary">Training</span> Programs
                </h3>
                <p>Need Help With ? Please Call 239-823-5070</p>
              </div>
            </div>

            {data &&
              data.map((items) => (
                <div class="col-lg-3 mb-4" onClick={() => getParams(items)}>
                  <div class="card">
                    <div class="card-body">
                      <div class="box">
                        <h4 className="">{items.course_name}</h4>
                        {/* <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Excepturi, quae?
              </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Course;
