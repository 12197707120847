const ThirdStep = ({earningTrendValue, handleRiskCheckboxChange,handleEarningTrendRadioChange }) => {
  const riskFactors = [
    "Industry in Decline",
    "Poor books and records",
    ">3 years in business",
  ];
  const earningTrend = [
    "Declining Revenues - 20% year over year, no turnaround yet",
    "Declining Revenues -20% year over year, turnaround started",
    "Steady Revenue, sustainable",
    "Increasing Revenues +20%year over year, sustainable",
    "Increasing Revenues +20%year over year, non-sustainable",
  ];
  const disbaledEarningTred=['Increasing Revenues +20%year over year, sustainable','Increasing Revenues +20%year over year, non-sustainable']
  return (
    <>
      <table class="table">
        <tbody>
          <tr>
            <th scope="row">Earnings Trend</th>
            <td>
              {earningTrend.map((trend) => {
                return (
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadiofault"
                      value={trend}
                      checked={trend === earningTrendValue}
                      id="flexRadioDefault1"
                      onChange={(e)=>handleEarningTrendRadioChange(e.target.value)}
                      disabled={disbaledEarningTred.includes(trend)}
                    />
                    <label class="form-check-label" >
                      {trend}
                    </label>
                  </div>
                );
              })}
            </td>
          </tr>

          <tr>
            <th scope="row">Risk Factors</th>
            <td>
              {riskFactors.map((risk) => {
                return (
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value={risk}
                      id="flexCheckDefault"
                      onChange={handleRiskCheckboxChange}
                    />
                    <label class="form-check-label" >
                      {risk}
                    </label>
                  </div>
                );
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default ThirdStep;
