import React, { useEffect, useState } from "react";
import AdminNav from "../../../components/navbar/adminNav";
import adminApi from "../../../services/adminApi";
import { HiOutlineTrash, HiOutlinePencilAlt } from "react-icons/hi";

import { AiOutlineClose } from "react-icons/ai";
import { Navigate, useNavigate } from "react-router-dom";

const UserManagement = () => {
  const navigate = useNavigate();
  const [data, setdata] = useState();
  const [singdata, setsingdata] = useState();
  const [modal, setModal] = useState(false);
  const [updatemodal, setupdate] = useState(false);
  const [Uid, setUid] = useState();

  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [phone, setphone] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [street, setStreet] = useState("");
  const [card, setCard] = useState("");
  const [expiry, setexpiry] = useState("");
  const [cvc, setcvc] = useState("");

  useEffect(() => {
    fetchUserData();
  }, []);
  //   useEffect(() => {}, [data]);
  const openModal = () => {
    setModal(!modal);
  };
  const openUpdateModal = () => {
    setupdate(!updatemodal);
  };

  const fetchUserData = async () => {
    try {
      const response = await adminApi.getUserData();
      if (response.data.payload.user) {
        setdata(response.data.payload.user);
      } else {
        console.log("Error");
      }
      console.log("response", response);
    } catch (err) {
      console.log("CATCH", err);
    }
  };

  const delete_user = async () => {
    try {
      console.log(Uid);
      const resp = await adminApi.deleteUser(Uid);
      if (resp.data.success == true) {
        fetchUserData();
      }
      console.log("resp", resp);
    } catch (err) {
      console.log("ERROR", err);
    }
  };
  const setfnameHandler = (e) => {
    setfname(e);
  };
  const setlnameHandler = (e) => {
    setlname(e);
  };
  const setphoneHandler = (e) => {
    setphone(e);
  };
  const setzipHandler = (e) => {
    setZip(e);
  };
  const setcityHandler = (e) => {
    setCity(e);
  };
  const setstateHandler = (e) => {
    setState(e);
  };
  const setstreetHandler = (e) => {
    setStreet(e);
  };
  const setcardHandler = (e) => {
    setCard(e);
  };
  const setexpiryHandler = (e) => {
    // console.log("ee", a);
    setexpiry(e);
  };
  const setcvcHandler = (e) => {
    setcvc(e);
  };

  return (
    <div>
      <AdminNav />
      <div className="m-4 ">
        <div className="adminUserInfo">
          <div class="mb-2 ml-2">
            <h4>User Details</h4>
            {/* <h3 class="heading">
              <span class="text-primary">User </span>Details
            </h3> */}
            {/* <p>Need Help With? Please Call 239-823-5070</p> */}
          </div>
          <div className="mainLayoutUser">
            {/* {data ? (
              data &&
              data.map((items) => (
                <div class=" mb-4">
                  <div class="">
                    <div class="contentCardUser ">
                      <div className="contentSvg">
                        <div className="contentDetail">
                          <h5>
                            {"Name: " + `${items.firstname} ${items.lastname}`}
                          </h5>
                          <p>{"Email: " + items.email}</p>
                          <p>{"Phone: " + items.phone}</p>
                          <p>
                            {"Card: " + items.cardInfo[0].card.split("-", 4)}
                          </p>
                          <p>{"Expiry: " + items.cardInfo[0].expiry}</p>
                          <p>{"Cvv: " + items.cardInfo[0].cvv}</p>
                          <p>{"City: " + items.location[0].city}</p>
                          <p>{"State: " + items.location[0].state}</p>
                          <p>{"Zip: " + items.location[0].zip}</p>
                          <p>{"Street: " + items.location[0].street}</p>
                        </div>

                        <div className="svgCon">
                          <HiOutlineTrash
                            className="delsvg"
                            onClick={() => {
                              openModal();
                              setUid(items.id);
                            }}
                          />
                          <HiOutlinePencilAlt
                            className="editsvg"
                            onClick={() => {
                              setsingdata(items);
                              openUpdateModal();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : ( */}
            {/* <div class=" mb-4"> */}
            {/* <div class=""> */}
            {/* <div class="contentCard text-center "> */}
            {/* <h5>Users not found</h5> */}
            {/* <div
                    dangerouslySetInnerHTML={{
                      __html: items.content_value.replaceAll("&lt;", "<"),
                    }}
                  /> */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            <div className="table_User">
              <table class="table main-table table-hover">
                <thead className="table_space">
                  <tr>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Email</th>
                    <th scope="col">Event</th>
                    {/* <th scope="col">Card</th>
                    <th scope="col">Expiry</th>
                    <th scope="col">CVV</th> */}
                    {/* <th scope="col">Zip</th>
                    <th scope="col">City</th>
                    <th scope="col">Street</th> */}
                    <th scope="col">Delete</th>
                    {/* <th scope="col">Edit</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((items) => (
                      <tr>
                        <td>{items.firstname}</td>
                        <td>{items.lastname}</td>
                        <td>{items.phone}</td>
                        <td>{items.email}</td>
                        <td>{items.eventInfo.event_date}</td>
                        
                        {/* <td>{items.cardInfo[0].card}</td>
                        <td>{items.cardInfo[0].expiry}</td>
                        <td>{items.cardInfo[0].cvv}</td> */}
                        {/* <td>{items.location[0].zip}</td>
                        <td>{items.location[0].city}</td>
                        <td>{items.location[0].street}</td> */}
                        <td>
                          {" "}
                          <HiOutlineTrash
                            className="delsvg"
                            onClick={() => {
                              openModal();
                              setUid(items.id);
                            }}
                          />
                        </td>
                        {/* <td>
                          <HiOutlinePencilAlt
                            className="editsvg"
                            onClick={() => {
                              navigate(`/user-edit-info/${items.id}`);
                              setsingdata(items);
                              //   openUpdateModal();
                            }}
                          />
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
              {/* <div className="">
                <button
                  className="btn btn-primary modal2__btn "
                  onClick={() => {
                    navigate("/admin-course");
                  }}
                >
                  Back
                </button>
              </div> */}
            </div>
          </div>

          {/* <!-- Modal --> */}
          {modal ? (
            <section className="modal1__bg">
              <div
                class="modal-btn"
                onClick={openModal}
                // href="javascript:void(0)"
                data-bs-toggle="modal"
                data-bs-target=".watchvideomodal"
              ></div>
              <div className="modal1__align">
                <div className="modal1__content" modal={modal}>
                  <div className="modal1__msg">
                    <h5>Are you sure you want to delete this user? </h5>
                    <div className="modal1__btn_layout">
                      <button
                        className="btn btn-primary modal1__btn delete_btn_content"
                        onClick={() => {
                          openModal();
                          delete_user();
                        }}
                      >
                        {/* {loader ? (
                        <i class="fa fa-circle-o-notch fa-spin mr-4"></i>
                      ) : (
                        ""
                      )} */}
                        Delete
                      </button>
                      <button
                        className="btn btn-primary modal1__btn "
                        onClick={openModal}
                      >
                        {/* {loader ? (
                        <i class="fa fa-circle-o-notch fa-spin mr-4"></i>
                      ) : (
                        ""
                      )} */}
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
          {/* <!-- END MODAL --> */}

          {/* <!-- Update Modal --> */}
          {updatemodal ? (
            <section className="modal2__bg">
              <div
                class="modal-btn"
                onClick={openUpdateModal}
                // href="javascript:void(0)"
                data-bs-toggle="modal"
                data-bs-target=".watchvideomodal"
              ></div>
              <div className="modal2__align">
                <div className="modal2__content" modal={modal}>
                  <div className="modal2__msg">
                    <div className="reg reg-scroll">
                      <div class="form-group">
                        <input
                          value={fname}
                          onChange={(e) => setfnameHandler(e.target.value)}
                          class="modal2__input"
                          type="text"
                          name="firstname"
                          placeholder="First Name"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={lname}
                          class="form-control"
                          onChange={(e) => setlnameHandler(e.target.value)}
                          type="text"
                          name="password"
                          placeholder="Last Name"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={phone}
                          class="form-control"
                          onChange={(e) => setphoneHandler(e.target.value)}
                          type="text"
                          name="password-repeat"
                          placeholder="Phone"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={zip}
                          class="form-control"
                          onChange={(e) => setzipHandler(e.target.value)}
                          type="text"
                          name="password-repeat"
                          placeholder="Zip"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={city}
                          class="form-control"
                          onChange={(e) => setcityHandler(e.target.value)}
                          type="text"
                          name="password-repeat"
                          placeholder="City"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={state}
                          class="form-control"
                          onChange={(e) => setstateHandler(e.target.value)}
                          type="text"
                          name="password-repeat"
                          placeholder="State"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={street}
                          class="form-control"
                          onChange={(e) => setstreetHandler(e.target.value)}
                          type="text"
                          name="password-repeat"
                          placeholder="Street"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={card}
                          class="form-control"
                          onChange={(e) => setcardHandler(e.target.value)}
                          type="text"
                          name="password-repeat"
                          placeholder="Card"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={cvc}
                          class="form-control"
                          onChange={(e) => setcvcHandler(e.target.value)}
                          type="text"
                          name="CVC"
                          placeholder="CVC"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={expiry}
                          class="form-control"
                          onChange={(e) => setexpiryHandler(e.target.value)}
                          type="month"
                          name="password-repeat"
                          placeholder="Expiry"
                        />
                      </div>
                    </div>
                    {/* <h5>Are you sure you want to delete this user? </h5> */}
                    <div className="modal2__btn_layout">
                      <button
                        className="btn btn-primary  modal2__btn "
                        onClick={() => {
                          openUpdateModal();
                          delete_user();
                        }}
                      >
                        {/* {loader ? (
                        <i class="fa fa-circle-o-notch fa-spin mr-4"></i>
                      ) : (
                        ""
                      )} */}
                        Update
                      </button>
                      <button
                        className="btn btn-primary modal2__btn "
                        onClick={openUpdateModal}
                      >
                        {/* {loader ? (
                        <i class="fa fa-circle-o-notch fa-spin mr-4"></i>
                      ) : (
                        ""
                      )} */}
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
          {/* <!-- END Update MODAL --> */}
        </div>
      </div>
    </div>
  );
};
export default UserManagement;
