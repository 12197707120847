import React from "react";
import "./progress.css";
const ProgressBar = () => {
  return (
    <div className="">
      <div className="">
        <div className="loading-bar animate">
          <span
            className="spanP"
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            50% Complete
          </span>
        </div>
        {/* <h2>Wordpress</h2> */}
      </div>
    </div>
  );
};

export default ProgressBar;
