import "../../../css/style.css";
import "../../../css/materialdesignicons.min.css";
import "../../../css/swiper-bundle.min.css";
import "../../../scss/_menu.scss";
import "../../../scss/_pricing.scss";
import "../../../css/modal.css";

const DonationCharity = () => {
  return (
    <div class="container">
      <div class="text-center mb-5">
        <h3 class="heading mb-3">
          <span class="text-primary">DONATING </span> OUR TRADING PROFITS TO
          CHARITY:
        </h3>
      </div>
      <p>
        We believe that the main purpose of life is not the fulfillment of
        happiness but instead, to be useful, honorable and compassionate. Below
        is a partial list of some of our past donations. Drawn From Water,
        Global Orphan Project, Vest N PDP, Kick Start, Utah Dream Center, One
        Laptop, Carol's Kitchen, Bear Necessities, Operation Helmet, Kids Meals,
        Unicef,Haiti, Forward, Stride, Forever FamDonation Imageilies, Feed the
        Children, Wounded Warriors, Keaton, Raphael Memorial, Xenophon Riding
        Center, ST. Francis Service Dogs, Teen Challenge, Seniors First, Tupelo
        Children's Mansion, Volunteers in Medicine, Be An Angel, Dogs for the
        Deaf, Gospel for Asia, Matthew 25, Smile Train,Mackhayley Foundation,
        Pets & People, Agape Corner, Eagles Nest, Access Adventure, Boys/Girls
        Country of Houston, New Orleans Area HFH, Girls on the Run, Diasabled
        American Veterans, Build4Life, CEDA Northwest, Honor Flight, Remote Area
        Medical, American Cancer Society, Water Wells, Ryan's Wells, Feed the
        Children...
      </p>
    </div>
  );
};
export default DonationCharity;
