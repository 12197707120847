import React, {useEffect, useRef, useState} from "react";
import Mike from "../../../video/thankyou.mp4";
import "./index.css";
import {useSearchParams} from "react-router-dom";
import eventApi from "../../../services/eventApi";

const Thankyou = () => {
  useEffect(() => {
    handleFetchAllEvents();
    if(window.fbq){
        console.log("fbq track thanks")
        window.fbq('track', 'PageView');
    }
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const [events, setEvents] = useState([]);
  const [userEvent, setUserEvents] = useState();

  const [showRegisterBtn, setShowRegisterBtn] = useState(false);
  const [showRegisterBottomStyle, setRegisterBottomStyle] = useState(false);
  const videoRef = useRef(null);
  const playVideo = () => {
    var video = document.getElementById("trainingVideo");
    video.play();
    document.getElementById("playButton").style.display = "none";
    document.getElementById("videoThumbnail").style.display = "none";
  };

  const handleFetchAllEvents = async () => {
    try {
      const response = await eventApi.fetchEvents();
      if (response.data.success) {
        setEvents(response.data.payload);
        let userevent = response?.data?.payload.find(
          (x) => x?._id == searchParams.get("id")
        );
        console.log("userevent", userevent);
        if (userevent) {
          setUserEvents(userevent);
        }
      }
    } catch (err) {
      alert("something went wrong");
    }
  };
  // const displayEventDate = (events) => {
  //   const _event =
  //   console.log("events list ==>",_event)
  //   return moment(events.find((x) => x._id == searchParams.get("id"))).format("MMMM D, YYYY h:mm A");
  // };

  const handleTimeUpdate = () => {
    if (
      videoRef.current.currentTime >= 209 &&
      videoRef.current.currentTime <= 214
    ) {
      setShowRegisterBtn(true);
    } else if (
      videoRef.current.currentTime >= 219 &&
      videoRef.current.currentTime <= 230
    ) {
      console.log("rehister button");
      setRegisterBottomStyle(true);
      setShowRegisterBtn(true);
    } else {
      setRegisterBottomStyle(false);
      setShowRegisterBtn(false);
    }
  };
  return (
    <>
      <div>
        

        {/* Rest of your component */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            background: "#000000",
          }}
        >
          <div
            style={{
              display: "flex",
              background: "#000000",

              padding: "30px",
              width: "85%",
              color: "white",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <h1 style={{ fontSize: "30px", textAlign: "center" }}>
                Congratulations You Have Successfully Registered For The Webinar
                <br />
                PLEASE WATCH The Video Below For Your Next Steps!﻿
              </h1>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "70%",
            //   background: "red",
            padding: "25px",
            marginTop: "30px",
            boxShadow: "4px 1px 10px gray",
            gap: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "30px",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <div className="">
              <div className="">
                {/* play 1st div */}

                {/* {showRegisterBtn && ( */}

                {/* )} */}

                {/* play 2nd div */}

                <video
                  ref={videoRef}
                  onTimeUpdate={handleTimeUpdate}
                  id="trainingVideo"
                  poster=""
                  className="modal__video-style"
                  src={Mike}
                  controls="controls"
                  autoPlay={true}
                  onPause={() => {}}
                  onPlay={() => {
                    document.getElementById("videoThumbnail").style.display =
                      "none";
                  }}
                  onEnded={() => {
                    document.getElementById("pauseText").style.display =
                      "block";
                    document.getElementById("playButton").style.display =
                      "block";
                  }}
                />
              </div>
            </div>

            <div
              style={{ display: "flex", width: "45%", flexDirection: "column" }}
            >
              <div
                style={{
                  background: "#B8212D",
                  padding: "6px",
                  fontSize: "30px",
                  color: "#ffffff",
                  textAlign: "center",
                }}
              >
                YOUR TICKET
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "26px",
                    color: "black",
                  }}
                >
                  I Will Show You:﻿﻿
                </p>

                <p style={{ fontSize: "18px", color: "black" }}>
                  The “Early Bird Strategy” Savvy Investors Use For Sky-High
                  Returns With Minimal Risk Exposure By Investing In Fledgeling
                  Companies With The Potential To Be The Next Google Or
                  Microsoft
                </p>
                <div
                  style={{
                    display: "flex",

                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      alignSelf: "center",
                      gap: "5px",
                    }}
                  >
                    <svg
                      height="30px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 14H9.31765C8.83513 14 8.59387 14 8.37806 14.0461C7.63116 14.2056 6.9853 14.7661 6.62346 15.569C6.51891 15.8009 6.44262 16.0765 6.29003 16.6278C6.10668 17.2901 6.01501 17.6213 6.00261 17.8884C5.95888 18.8308 6.46818 19.6817 7.22441 19.9297C7.43875 20 7.72864 20 8.30844 20H11.5M11 14L12 15L13 14M11 14H13M13 14H14.6824C15.1649 14 15.4061 14 15.6219 14.0461C16.3688 14.2056 17.0147 14.7661 17.3765 15.569C17.4811 15.8009 17.5574 16.0765 17.71 16.6278C17.8933 17.2901 17.985 17.6213 17.9974 17.8884C18.0411 18.8308 17.5318 19.6817 16.7756 19.9297C16.5613 20 16.2714 20 15.6916 20H12.5M11.5 20L12 16.5L12.5 20M11.5 20H12.5M16 8C16 10.2091 14.2091 12 12 12C9.79086 12 8 10.2091 8 8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8Z"
                        stroke="#000000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                      />
                    </svg>

                    <div style={{ fontWeight: "bold", fontSize: "22px" }}>
                      Host:
                    </div>
                    <div style={{ fontSize: "20px" }}>Mike</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",

                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      alignSelf: "center",
                      gap: "5px",
                    }}
                  >
                    <svg
                      width="30px"
                      height="25px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 9C2 7.11438 2 6.17157 2.58579 5.58579C3.17157 5 4.11438 5 6 5H18C19.8856 5 20.8284 5 21.4142 5.58579C22 6.17157 22 7.11438 22 9C22 9.4714 22 9.70711 21.8536 9.85355C21.7071 10 21.4714 10 21 10H3C2.5286 10 2.29289 10 2.14645 9.85355C2 9.70711 2 9.4714 2 9Z"
                        fill="#222222"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2 18C2 19.8856 2 20.8284 2.58579 21.4142C3.17157 22 4.11438 22 6 22H18C19.8856 22 20.8284 22 21.4142 21.4142C22 20.8284 22 19.8856 22 18V13C22 12.5286 22 12.2929 21.8536 12.1464C21.7071 12 21.4714 12 21 12H3C2.5286 12 2.29289 12 2.14645 12.1464C2 12.2929 2 12.5286 2 13V18ZM7 15C7 14.5286 7 14.2929 7.14645 14.1464C7.29289 14 7.5286 14 8 14H10C10.4714 14 10.7071 14 10.8536 14.1464C11 14.2929 11 14.5286 11 15C11 15.4714 11 15.7071 10.8536 15.8536C10.7071 16 10.4714 16 10 16H8C7.5286 16 7.29289 16 7.14645 15.8536C7 15.7071 7 15.4714 7 15ZM7.14645 18.1464C7 18.2929 7 18.5286 7 19C7 19.4714 7 19.7071 7.14645 19.8536C7.29289 20 7.5286 20 8 20H10C10.4714 20 10.7071 20 10.8536 19.8536C11 19.7071 11 19.4714 11 19C11 18.5286 11 18.2929 10.8536 18.1464C10.7071 18 10.4714 18 10 18H8C7.5286 18 7.29289 18 7.14645 18.1464ZM13 15C13 14.5286 13 14.2929 13.1464 14.1464C13.2929 14 13.5286 14 14 14H16C16.4714 14 16.7071 14 16.8536 14.1464C17 14.2929 17 14.5286 17 15C17 15.4714 17 15.7071 16.8536 15.8536C16.7071 16 16.4714 16 16 16H14C13.5286 16 13.2929 16 13.1464 15.8536C13 15.7071 13 15.4714 13 15ZM13.1464 18.1464C13 18.2929 13 18.5286 13 19C13 19.4714 13 19.7071 13.1464 19.8536C13.2929 20 13.5286 20 14 20H16C16.4714 20 16.7071 20 16.8536 19.8536C17 19.7071 17 19.4714 17 19C17 18.5286 17 18.2929 16.8536 18.1464C16.7071 18 16.4714 18 16 18H14C13.5286 18 13.2929 18 13.1464 18.1464Z"
                        fill="#222222"
                      />
                      <path
                        d="M7 3L7 6"
                        stroke="#222222"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M17 3L17 6"
                        stroke="#222222"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>

                    <div style={{ fontWeight: "bold", fontSize: "22px" }}>
                      Date:
                    </div>
                    <div style={{ fontSize: "20px", whiteSpace: "nowrap" }}>
                      {userEvent &&
                        userEvent != "undefined" &&
                        userEvent.event_date}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <h2>
              Make Sure You Don’t Miss This FREE Online Training by Mike Coval
            </h2>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <button
                onClick={() => {
                  userEvent && window.open(userEvent?.event_link);
                }}
                className="h_btn"
                style={{
                  width: "35%",
                  padding: "15px",
                  background: "#B8212D",
                  border: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  outline: "none",
                }}
              >
                <span
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                    fontWeight: "bold",
                  }}
                >
                  Click to Go To Webinar Link
                </span>
                <svg
                  width="35px"
                  height="35px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  id="right-circle"
                  className="icon glyph"
                  fill="white"
                  style={{ marginLeft: "5px" }}
                >
                  <path
                    d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm2.71,10.71-3,3a1,1,0,0,1-1.42,0,1,1,0,0,1,0-1.42L12.59,12l-2.3-2.29a1,1,0,0,1,1.42-1.42l3,3A1,1,0,0,1,14.71,12.71Z"
                    // style="fill:#231f20"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            background: "#000000",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              background: "#000000",

              padding: "50px",
              width: "85%",
              color: "white",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  fontSize: "15px",
                  textAlign: "center",
                  color: "white",
                }}
              >
                © 2023 ARD-EDU. All Rights Reserved.
                <br />
                Privacy | Terms | Disclaimer
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Thankyou;
