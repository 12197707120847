import AuthNav from "../../../components/navbar/authNav";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../../../css/style.css";
import "../../../css/materialdesignicons.min.css";
import "../../../css/swiper-bundle.min.css";
import "../../../scss/_menu.scss";
import "../../../scss/_pricing.scss";
import "../../../css/modal.css";
import axios from "../../../axios";
import { CONTACT_US_API, INCOME_TRADER_REGISTER } from "../../../api/apiRoutes";
import Footer from "../../../components/Homepage/footer";
import MultiSteps from "./MultiSteps";
import PriorVideos from "./PriorVideos";
import Testimonials from "./Testimonials";
import ExpoStocksVideo from "./ExpoStockVideo";
import DonationCharity from "./DonationCharity";

const WelcomeAfterRegisterPage = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);

  const [subject, setSubject] = useState("");

  const [navRes, setNav] = useState(false);
  const [showRegisterBtn, setShowRegisterBtn] = useState(false);
  const [showRegisterBottomStyle, setRegisterBottomStyle] = useState(false);
  const videoRef = useRef(null);

  const [alertRed, setAlertRed] = useState(false);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const handleWebsiteRegistration = async (e) => {
    e.preventDefault();
    const payload = {
      firstName,
      lastName,
      email,
      phone,
      password,
    };
    if (firstName && lastName && email && phone && password && repeatPassword) {
      try {
        if (password != repeatPassword) {
          errorMethod("Passwords don't match");
          return;
        }
        if (!validateEmail(email)) {
          errorMethod("Please enter a valid email");
          return;
        }
        setLoader(true);
        const res = await axios.post(INCOME_TRADER_REGISTER, payload);
        if (res.data.success) {
          successMethod("Thanks For Your Registration")
          clearForm();
        } else {
          errorMethod(res.data.message);
        }
        setLoader(false);
      } catch (err) {
        errorMethod("Server Side error");
        setLoader(false);
      }
    } else {
      errorMethod("Fill all the Required Fields**");
    }
  };
  const successMethod = (message) => {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 3000);
    setMessage(message);
  };
  const errorMethod = (message) => {
    setAlertRed(true);
    setTimeout(() => {
      setAlertRed(false);
      setMessage("");
    }, 3000);
    setMessage(message);
  };
  const clearForm = () => {
    setFirstName("");
    setEmail("");
    setLastName("");
    setPhone("");
    setPassword("");
    setRepeatPassword("");
  };
  const validateEmail = (email) => {
    const pattern = /^[\w\.-]+@[\w\.-]+\.\w+$/;
    return pattern.test(email);
  };

  return (
    <div data-bs-spy="scroll" data-bs-target="#navbar" data-bs-offset="71">
      <AuthNav />

      <div style={{ marginTop: "100px" }}>
        <section style={{ marginBottom: "40px" }}>
          <div className="container">
            {/* <div class="row justify-content-center">
              <div class="col-lg-12">
                <div class="text-center ">
                  <h3 class="heading ">
                    <span class="text-primary">Welcome </span>Incometrader
                  </h3>
                </div>
              </div>
            </div> */}
            <ExpoStocksVideo />
          </div>
        </section>

        <section class="section contact " id="contact">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <div class="text-center mb-5">
                  <h3 class="heading mb-3">
                    <span class="text-primary">Website </span>Registration
                  </h3>
                </div>
              </div>
            </div>
            <div class="row justify-content-around g-4">
              <div class="col-lg-6 ">
                {alertRed ? <div class="font-alert-c ml-3">{message}</div> : ""}
                {alert ? <div class="font-alert-c-g ml-3">{message}</div> : ""}
                <form onSubmit={(e) => e.preventDefault()}>
                  <div class="col-lg-12">
                    <div class="position-relative mb-3">
                      <span class="input-group-text">
                        <i class="mdi mdi-account-outline"></i>
                      </span>
                      <input
                        value={firstName}
                        name="firstname"
                        id="subject"
                        type="text"
                        class="form-control"
                        placeholder="First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="position-relative mb-3">
                      <span class="input-group-text">
                        <i class="mdi mdi-account-outline"></i>
                      </span>
                      <input
                        value={lastName}
                        name="lastname"
                        id="subject"
                        type="text"
                        class="form-control"
                        placeholder="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="position-relative mb-3">
                      <span class="input-group-text">
                        <i class="mdi mdi-email-outline"></i>
                      </span>
                      <input
                        value={email}
                        name="email"
                        id="email"
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="position-relative mb-3">
                      <span class="input-group-text align-items-start">
                        <i class="mdi mdi-cellphone"></i>
                      </span>
                      <input
                        value={phone}
                        name="Phone"
                        id="phone"
                        type="text"
                        class="form-control"
                        placeholder="Phone"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="position-relative mb-3">
                      <span class="input-group-text align-items-start">
                        <i class="mdi mdi-comment-text-outline"></i>
                      </span>
                      <input
                        value={password}
                        name="password"
                        id="subject"
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="position-relative mb-3">
                      <span class="input-group-text align-items-start">
                        <i class="mdi mdi-comment-text-outline"></i>
                      </span>
                      <input
                        value={repeatPassword}
                        name="repassword"
                        id="repassword"
                        type="password"
                        class="form-control"
                        placeholder="Re-enter Password"
                        onChange={(e) => setRepeatPassword(e.target.value)}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-lg-4">
                <div class="contact-details mb-4 mb-lg-0">
                  <p class="mb-3">
                    <i class="mdi mdi-email-outline align-middle text-muted fs-20 me-2"></i>{" "}
                    <span class="fw-medium">info@stockmarket-edu.com</span>
                  </p>
                  <p class="mb-3">
                    <i class="mdi mdi-web align-middle text-muted fs-20 me-2"></i>{" "}
                    <span class="fw-medium">www.ardedu.com</span>
                  </p>
                  <p class="mb-3">
                    <i class="mdi mdi-phone align-middle text-muted fs-20 me-2"></i>{" "}
                    <span class="fw-medium">239-823-5070</span>
                  </p>
                  {/* <p class="mb-3">
                  <i class="mdi mdi-hospital-building text-muted fs-20 me-2"></i>{" "}
                  <span class="fw-medium">9:00 AM - 6:00 PM</span>
                </p> */}
                  <p class="mb-3">
                    <i class="mdi mdi-map-marker-outline text-muted fs-20 me-2"></i>{" "}
                    <span class="fw-medium">
                      6338 Presidential Court #204 Ft. Myers Florida 33919
                    </span>
                  </p>
                  <div className="mt-4 ">
                    <button
                      className="btn btn-primary"
                      disabled={loader}
                      onClick={handleWebsiteRegistration}
                    >
                      {loader ? (
                        <i className="fa fa-circle-o-notch fa-spin mr-3"></i>
                      ) : (
                        ""
                      )}
                      Start Registration
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ marginBottom: "40px" }}>
          <MultiSteps />
        </section>

        <section style={{ marginBottom: "40px" }} class="">
          <PriorVideos />
        </section>

        <section class="section-welcome testimonials" id="testimonials">
          <Testimonials />
        </section>

        <section style={{ marginBottom: "40px" }} class="">
          <DonationCharity />
        </section>
      </div>
      <Footer />
    </div>
  );
};
export default WelcomeAfterRegisterPage;
