import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import userApi from "../../../services/userApi";
import "../../../css/style.css";
import { decode as base64_decode, encode as base64_encode } from "base-64";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import contentApi from "../../../services/contentApi";
import courseApi from "../../../services/courseApi";
import Navbar from "../../../components/navbar/navbar";
import "../../../css/admin.css";
import "../../../css/style.css";
import AdminNav from "../../../components/navbar/adminNav";
const Content = () => {
  const navigate = useNavigate();
  useEffect(() => {
    fetchAllCourses();
  }, []);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"], //"blockquote"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image", "link"],
      // ["clean"],
    ],
  };
  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "color",
    "background",
    "align",
    "list",
    "bullet",
    "link",
    "image",
    "video",
  ];

  const [contentVal, setContentVal] = useState("");
  const [contentTitle, setContentTitle] = useState("");
  const [contentCategory, setContentCategory] = useState("");
  const [courseOptions, setCourseOptions] = useState([]);
  const [alertRed, setAlertRed] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [alert, setAlert] = useState(false);

  const fetchAllCourses = async () => {
    try {
      const pathname = window.location.pathname; // "/course-content/123"
      const pathParts = pathname.split("/"); // ["", "course-content", "123"]
      const path = pathParts[2];
      const response = await courseApi.fetch_courses();
      if (response.data.success) {
        const course = response.data.payload.find((x) => x.course_path == path);
        setCourseOptions(course);
      }
    } catch (err) {
      alert(err.message);
    }
  };
  const handleCreateContent = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      if (!contentTitle || !contentVal) {
        setLoader(false);
        setAlertRed(true);
        setTimeout(() => {
          setAlertRed(false);
        }, 2000);
        setError("All fields are required");
        return;
      }
      const payload = {
        content_title: contentTitle,
        content_category: courseOptions.course_path,
        content_value: base64_encode(contentVal),
      };
      const response = await contentApi.create_content(payload);
      if (response.data.success) {
        setLoader(false);
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 2000);
        setError(response.data.message);
        // alert(response.data.message);
        setContentVal("");
        setContentTitle("");
        // setContentCategory("");
      }
    } catch (err) {
      setLoader(false);
      setAlertRed(true);
      setTimeout(() => {
        setAlertRed(false);
      }, 2000);

      // setError(err.message);
      alert(err.message);
      setContentVal("");
      setContentTitle("");
      // setContentCategory("");
    }
  };

  return (
    <div>
      <AdminNav />

      <div className="admin">
        <div className="back_course">
          <p
            className="back_course_para"
            onClick={() => {
              navigate("/admin-course-mangement");
            }}
          >
            Back to Course
          </p>
        </div>

        <div class="mb-4">
          <h4>Create a New Content</h4>
          {/* <h3 class="heading">
            Admin <span class="text-primary">Panel</span>
          </h3>
          <p>Need Help With ? Please Call 239-823-5070</p> */}
        </div>
        <div className="admin-alert">
          {alert ? <div class=" font-alert3">{error}</div> : ""}

          {alertRed ? (
            <div class="font-alert1">
              <strong className="mr-2"></strong> {error}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="mb-2">
          {/* <h5>Content Category</h5> */}
          <div className="admin-input">
            {courseOptions && <h5>{courseOptions.course_name} </h5>}
            {/* <select
              className="w-25  admin-input-content"
              name="category"
              defaultValue={"selected"}
              placeholder="Enter title for your content"
              onChange={(e) => setContentCategory(e.target.value)}
            >
              <option value={"selected"} disabled>
                {" "}
                Please select a course
              </option>
              {courseOptions.map((course, index) => {
                return (
                  <option key={index} value={course.course_path}>
                    {course.course_name}
                  </option>
                );
              })}
            </select> */}
          </div>
        </div>

        <div className="mb-4 ">
          {/* <h2>Content Title</h2> */}
          <h6>Content Title</h6>
          <div className="admin-input">
            <input
              value={contentTitle}
              className="w-25  admin-input-content"
              type="text"
              name="text"
              placeholder="Enter title for your content"
              onChange={(e) => setContentTitle(e.target.value)}
            />
          </div>
        </div>

        <div className="mb-4">
          <h6>Add Content</h6>
          <div>
            {/* <CKEditor
              editor={ClassicEditor}
              data={contentVal}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContentVal(data);
              }}
            /> */}

            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              value={contentVal}
              onChange={(data) => {
                // console.log(data);
                setContentVal(data);
              }}
            />
          </div>
        </div>

        <div className="admin_btn_layout">
          {/* <p
            className="text-primary admin-btn-course "
            onClick={() => {
              navigate("/admin-course");
            }}
          >
            Create-Course
          </p> */}

          <button
            className="btn btn-primary admin-btn delete_btn_content "
            onClick={() => {
              navigate("/admin-course");
            }}
          >
            {/* {loader ? <i class="fa fa-circle-o-notch fa-spin mr-4"></i> : ""} */}
            Create-Course
          </button>
          <button
            className="btn btn-primary admin-btn "
            onClick={handleCreateContent}
          >
            {loader ? <i class="fa fa-circle-o-notch fa-spin mr-4"></i> : ""}
            Save Content
          </button>
        </div>
      </div>
    </div>
  );
};

export default Content;
