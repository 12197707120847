import React, { useState, useEffect } from "react";
import "../../../../css/style.css";
import { useNavigate, Link } from "react-router-dom";
import "../../../../css/formSignin.css";
import { BiLoaderAlt } from "react-icons/bi";
import adminApi from "../../../../services/adminApi";
import Footer from "../../../../components/footer";
import AuthNav from "../../../../components/navbar/authNav";
import { SigninValidationSchema } from "../../../../schema_validator";
import * as Yup from "yup";

const AdminSignin = () => {
  const navigate = useNavigate();
  const schema = Yup.object().shape({
    fname: Yup.string().required("First name is required"),
    lname: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
    phone: Yup.string().required("Phone is required"),
  });
  useEffect(() => {
    if (localStorage.getItem("Admin")) {
      navigate("/user-mangement");
    }
  });
  //   const [confirmpassword, setconfirmpassword] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [showpass, setpass] = useState(false);
  const [loader, setLoader] = useState(false);
  const [alertRed, setAlertRed] = useState(false);
  const [error, setError] = useState("");
  const [alert, setAlert] = useState(false);
  const setemailHandler = (e) => {
    setemail(e);
  };
  const setpasswordHandler = (e) => {
    setpassword(e);
  };

  const signin = async () => {
    const values = {
      email: email,
      password: password,
    };
    setLoader(true);
    if (email && password) {
      try {
        let response = await adminApi.adminSignIn(values);
        console.log("response", response);

        if (response.data.admin) {
          setLoader(false);
          localStorage.setItem("Admin", response.data.admin.isAdmin);
          localStorage.setItem("token", response.data.tokens.access.token);
          // localStorage.setItem("isAuthenticated", true);
          navigate("/user-mangement");
          // setemail("");
          console.log(response.data.tokens.access.token);

          setpassword("");
        } else {
          console.log("ERRR");
          // setemail("");
          // setpassword("");
          setError(response.data.message);
          setAlertRed(true);
          setTimeout(() => {
            setAlertRed(false);
          }, 2500);
          setLoader(false);
        }
      } catch (err) {
        console.log("err", err);
        // setemail("");
        // setpassword("");
        setAlertRed(true);
        setTimeout(() => {
          setAlertRed(false);
        }, 2500);
        setLoader(false);
        setError(err.response.data.message);
      }
    } else {
      setLoader(false);
      setAlertRed(true);
      setTimeout(() => {
        setAlertRed(false);
      }, 2500);
      setLoader(false);
      setError("Fill all the Required Fields");
    }
  };

  return (
    <div>
      <AuthNav />
      <div class="register-photoS mt-4">
        <div class="form-container">
          <div class="image-holder"></div>
          <form onSubmit={(e) => e.preventDefault()}>
            <div class="">
              <div class="text-center mb-5">
                <h3 class="heading3">
                  <span class="text-primary">Admin</span> Sign in
                </h3>
              </div>
            </div>

            {alertRed ? <div class="font-alert">{error}</div> : ""}

            <div class="form-group">
              <input
                value={email}
                autoComplete="new-password"
                class="form-control"
                type="email"
                name="email"
                placeholder="Email"
                onChange={(e) => setemailHandler(e.target.value)}
              />
            </div>
            <div class="form-group">
              <input
                value={password}
                // autoComplete="password"
                class="form-control"
                type={showpass ? "text" : "password"}
                name="password"
                placeholder="Password"
                onChange={(e) => setpasswordHandler(e.target.value)}
              />
            </div>
            {/* <label class="container">
              Two
              <input type="checkbox" />
              <span class="checkmark"></span>
            </label> */}
            <div class="form-group">
              <div class="form-check">
                <label class="form-check-label"
                style={{cursor:"pointer"}}>
                  <input
                    class="form-check-input test "
                    type="checkbox"
                    style={{cursor:"pointer"}}
                    onClick={() => setpass(!showpass)}
                  />
                  <span class="test" style={{cursor:"pointer"}}></span>
                  Show Password
                </label>
              </div>
            </div>
            {/* <div class="form-group text-left">
              <div
                class=""
                onClick={() => {
                  navigate("/forget-password");
                }}
              >
                <a class="already2">Forget Password</a>
              </div>
            </div> */}
            <div class=" row justify-content-center mb-2">
              <button class="btn btn-primary" onClick={signin}>
                {loader ? (
                  <i class="fa fa-circle-o-notch fa-spin mr-4"></i>
                ) : (
                  ""
                )}
                Sign In
              </button>
            </div>
            {/* <a onClick={() => navigate("/admin-sign-up")} class="already1">
              You don't have an account? Sign up.
            </a> */}
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

//  #bfc6ca
export default AdminSignin;
