import React, { useEffect, useState } from "react";
import Navbar from "../../../components/navbar/navbar";
import coursesApi from "../../../services/coursesApi";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import "../../../css/courseContent.css";
import { HiOutlineTrash, HiOutlinePencil } from "react-icons/hi";
import Footer from "../../../components/footer";
import AdminNav from "../../../components/navbar/adminNav";
import contentApi from "../../../services/contentApi";
import courseApi from "../../../services/courseApi";
export const AdminCourseContent = () => {
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"], //"blockquote"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image", "link"],
      // ["clean"],
    ],
  };
  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "color",
    "background",
    "align",
    "list",
    "bullet",
    "link",
    "image",
    "video",
  ];
  const [param, setParam] = useState();
  const [data, setData] = useState("");
  const [contentTitle, setcontentTitle] = useState();
  const [loading, setLoading] = useState(false);
  const [contentCategory, setContentCategory] = useState();
  const [courseOptions, setCourseOptions] = useState([]);
  const [modal, setModal] = useState(false);
  const [contentData, setcontentData] = useState();
  const [updatemodal, setupdate] = useState(false);
  const [cId, setcId] = useState("");
  const [conId, setcon] = useState();
  const [paraId, setpara] = useState();
  const [loader, setloader] = useState(false);
  const [error, setError] = useState("");
  const [alertRed, setalertRed] = useState(false);
  const [alert, setalert] = useState(false);
  const navigate = useNavigate();
  const signout = () => {
    localStorage.clear();
    navigate("/sign-in");
  };
  useEffect(() => {
    // console.log("id==>", param_id);
    const pathname = window.location.pathname; // "/course-content/123"
    const pathParts = pathname.split("/"); // ["", "course-content", "123"]
    const id = pathParts[2];
    setpara(id);
    fetchAllCourses();
    coursesData(id);
  }, []);

  const openModal = () => {
    setModal(!modal);
  };

  const openUpdateModal = () => {
    setupdate(!updatemodal);
  };

  const editContent = async () => {
    const values = {
      id: conId,
      content_title: contentTitle,
      content_value: contentData,
      content_category: contentCategory,
    };
    try {
      if (contentTitle && contentData) {
        setloader(true);
        const resp = await contentApi.edit_content(values);
        console.log("resp", resp);
        if (resp.data.success == true) {
          coursesData(paraId);
          setError(resp.data.message);
          setloader(false);
          setalert(true);
          setTimeout(() => {
            setalert(false);
            openUpdateModal();
          }, 1000);
        }
        console.log("resp ", resp);
      } else {
        setloader(false);
        setalertRed(true);
        setTimeout(() => {
          setalertRed(false);
          // openUpdateModal();
        }, 1000);
        setError("Fill the Required Fields");
      }
    } catch (err) {
      setError(err);
    }
  };

  const coursesData = async (param) => {
    console.log("param", param);
    try {
      const resp = await coursesApi.getContentData(param);
      setLoading(true);
      if (resp.data.success == true) {
        console.log("Courses", resp.data.payload);
        setData(resp.data.payload);
        setLoading(false);
        // console.log("data", data.data.payload.);
        // setdata(resp.data.payload);
      } else {
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const fetchAllCourses = async () => {
    try {
      const response = await courseApi.fetch_courses();
      if (response.data.success) {
        setCourseOptions(response.data.payload);
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const handleDeleteContent = async () => {
    console.log("delete_content_Id", cId);
    setloader(true);
    try {
      const response = await contentApi.delete_content(cId);
      console.log("Res", response);
      if (response.data.success) {
        // alert("Deleted");
        setloader(false);
        coursesData(paraId);
      }
    } catch (err) {
      setloader(false);
      setalertRed(true);
      setError(err);
    }
  };
  return (
    <div>
      <AdminNav />

      <section class="section services" id="pricing">
        <div class="container mt-5">
          <div class="row g-4">
            <div class="col-lg-12  mt-3">
              <div class=" mb-3 updateDiv">
                {/* <p
                  className="text-primary admin-btn-course1 "
                  onClick={() => {
                    navigate("/course");
                  }}
                >
                  Back to Course
                </p> */}
                <h4>Course Content</h4>

                <button
                  className="btn btn-primary modal2__btn updateDiv_para "
                  onClick={() => {
                    navigate(`/admin-content/${paraId}`);
                  }}
                >
                  {/* {loader ? (
                        <i class="fa fa-circle-o-notch fa-spin mr-4"></i>
                      ) : (
                        ""
                      )} */}
                  Create Content
                </button>
                {/* <h3 class="heading ">
                  Course <span class="text-primary">Content</span>
                </h3>
                <p>Need Help With ? Please Call 239-823-5070</p> */}
              </div>
            </div>

            <div className="mainLayout">
              {loading ? (
                <div class=" mb-4">
                  <div class="">
                    <div class="contentCard text-center ">
                      <h5>Content is loading</h5>
                    </div>
                  </div>
                </div>
              ) : (
                data &&
                data.length > 0 &&
                data.map((items) => (
                  <div class=" mb-4">
                    <div class="">
                      <div class="contentCard ">
                        <div className="admin_content_lay">
                          <h4>{items.content_title}</h4>
                          <div className="admin_svg_lay">
                            <HiOutlineTrash
                              className="delsvg"
                              onClick={() => {
                                openModal();
                                setcId(items._id);
                                console.log("CID", items);
                              }}
                            />
                            <HiOutlinePencil
                              className="editsvg"
                              onClick={() => {
                                setcon(items._id);
                                // setcontent(items);
                                setcontentData(
                                  items.content_value.replaceAll("&lt;", "<")
                                );
                                setcontentTitle(items.content_title);
                                setContentCategory(items.content_category);
                                // console.log(contentData);
                                openUpdateModal();
                              }}
                            />
                          </div>
                        </div>

                        <div
                          className="ql-editor"
                          dangerouslySetInnerHTML={{
                            __html: items.content_value.replaceAll("&lt;", "<"),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))
              )}
              {!loading && data && data.length === 0 && (
                <div class=" mb-4">
                  <div class="">
                    <div class="contentCard text-center ">
                      <h5>No content was available for this course.</h5>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* <!-- Update Modal --> */}
            {updatemodal ? (
              <section className="modal2__bg">
                <div
                  class="modal-btn"
                  onClick={openUpdateModal}
                  // href="javascript:void(0)"
                  data-bs-toggle="modal"
                  data-bs-target=".watchvideomodal"
                ></div>
                <div className="modal2__align">
                  <div className="modal2__content">
                    <div className="modal2__msg">
                      <div className="reg reg-scroll h-50">
                        <div className="mb-4 ">
                          {alertRed ? (
                            <div class="modal-alert-msg ">{error}</div>
                          ) : (
                            ""
                          )}
                          {alert ? (
                            <div class="modal-alert-msg1 ">{error}</div>
                          ) : (
                            ""
                          )}
                          {/* <h2>Content Title</h2> */}
                          <h6>Content Title</h6>
                          <div className="admin-input">
                            <input
                              value={contentTitle}
                              className="w-25  admin-input-content"
                              type="text"
                              name="text"
                              placeholder="Enter title for your content"
                              onChange={(e) => setcontentTitle(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="mb-4 ">
                          <h6>Content Category</h6>
                          <div className="admin-input">
                            <select
                              className="w-25  admin-input-content"
                              name="category"
                              value={contentCategory}
                              // defaultValue={"selected"}
                              placeholder="Select title for your content"
                              onChange={(e) => {
                                console.log("e.target.value", e.target.value);
                                setContentCategory(e.target.value);
                              }}
                            >
                              {courseOptions.map((course, index) => {
                                // console.log("course option",course)
                                return (
                                  <option
                                    key={index}
                                    value={course.course_path}
                                  >
                                    {course.course_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="mb-4">
                          <h6>Add Content</h6>
                          <div>
                            <ReactQuill
                              theme="snow"
                              modules={modules}
                              formats={formats}
                              value={contentData}
                              onChange={(data) => {
                                console.log(data);
                                setcontentData(data);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="modal2__btn_layout">
                        <button
                          className="btn update_btn_content modal2__btn "
                          onClick={() => {
                            editContent();
                          }}
                        >
                          {loader ? (
                            <i class="fa fa-circle-o-notch fa-spin mr-2"></i>
                          ) : (
                            ""
                          )}
                          Update
                        </button>
                        <button
                          className="btn btn-primary modal2__btn "
                          onClick={openUpdateModal}
                        >
                          {/* {loader ? (
                        <i class="fa fa-circle-o-notch fa-spin mr-4"></i>
                      ) : (
                        ""
                      )} */}
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : null}
            {/* <!-- END Update MODAL --> */}

            {/* <!-- Modal --> */}
            {modal ? (
              <section className="modal1__bg">
                <div
                  class="modal-btn"
                  onClick={openModal}
                  // href="javascript:void(0)"
                  data-bs-toggle="modal"
                  data-bs-target=".watchvideomodal"
                ></div>
                <div className="modal1__align">
                  <div className="modal1__content" modal={modal}>
                    {alertRed ? (
                      <div class="modal-alert-msg ">{error}</div>
                    ) : (
                      ""
                    )}
                    <div className="modal1__msg">
                      <h5>Are you sure you want to delete this content? </h5>
                      <div className="modal1__btn_layout">
                        <button
                          className="btn btn-primary modal1__btn delete_btn_content"
                          onClick={() => {
                            openModal();
                            handleDeleteContent();
                          }}
                        >
                          {loader ? (
                            <i class="fa fa-circle-o-notch fa-spin mr-2"></i>
                          ) : (
                            ""
                          )}
                          Delete
                        </button>
                        <button
                          className="btn btn-primary modal1__btn "
                          onClick={openModal}
                        >
                          {/* {loader ? (
                        <i class="fa fa-circle-o-notch fa-spin mr-4"></i>
                      ) : (
                        ""
                      )} */}
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : null}
            {/* <!-- END MODAL --> */}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default AdminCourseContent;
