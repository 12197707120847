import React, { useEffect, useState } from "react";
import AuthNav from "../../../components/navbar/authNav";

import { HiOutlineTrash, HiOutlinePencilAlt } from "react-icons/hi";

import { AiOutlineClose } from "react-icons/ai";
import { Navigate, useNavigate } from "react-router-dom";
import AdminNav from "../../../components/navbar/adminNav";
import coursesApi from "../../../services/coursesApi";
import adminApi from "../../../services/adminApi";
const GetAlluser = () => {
  const navigate = useNavigate();
  const [data, setdata] = useState();
  const [updatemodal, setupdate] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [courseDesc, setCourseDesc] = useState("");
  const [courseId, setCourseId] = useState("");
  const [Uid, setId] = useState("");
  const [modal, setModal] = useState(false);
  const [loader, setloader] = useState(false);
  const [error, setError] = useState("");
  const [alertRed, setalertRed] = useState(false);
  React.useEffect(() => {
    coursesData();
  }, []);

  const coursesData = async () => {
    try {
      const resp = await coursesApi.getCoursesData();
      if (resp.data.success == true) {
        setdata(resp.data.payload);
      } else {
      }
    } catch (err) {}
  };

  const deleteCourse = async () => {
    try {
      setloader(true);

      const resp = await adminApi.deleteCourse(Uid);
      if (resp.data.success == true) {
        setloader(false);
        coursesData();
      }
      // console.log("resp", resp);
    } catch (err) {
      setloader(false);
    }
  };
  const updateCourse = async () => {
    try {
      if (courseName && courseDesc) {
        setloader(true);
        const payload = {
          course_name: courseName,
          id: courseId,
          // course_path: courseName.toLowerCase().replace(" ", "_"),
          course_desc: courseDesc,
        };
        const resp = await adminApi.updateCourse(payload);
        if (resp.data.success == true) {
          setloader(false);
          openUpdateModal();
          coursesData();
        }
      } else {
        setloader(false);
        setalertRed(true);
        setTimeout(() => {
          setalertRed(false);
        }, 1000);
        setError("Fill the Required Fields");
      }
    } catch (err) {
      // alert(err);
      setError(err);
      setloader(false);
    }
  };
  const openModal = () => {
    setModal(!modal);
  };

  const openUpdateModal = (item = null) => {
    if (item) {
      setCourseName(item.course_name);
      setCourseDesc(item.course_desc);
      setCourseId(item._id);
      setupdate(!updatemodal);
    } else {
      setCourseName("");
      setCourseDesc("");
      setCourseId("");
      setupdate(!updatemodal);
    }
  };
  return (
    <div>
      {/* <AuthNav /> */}
      <AdminNav />
      <div className="m-4 ">
        <div className="adminUserInfo">
          <div className="modal3__btn_layout">
            <div class=" mb-2 ml-1">
              <h4 class="">Courses</h4>

              {/* <p>Need Help With? Please Call 239-823-5070</p> */}
            </div>
            <button
              className="btn btn-primary modal3__btn mr-4 "
              onClick={() => {
                navigate("/admin-course");
              }}
            >
              + ADD
            </button>
          </div>

          <div className="mainLayoutUser2">
            <div className="table_User">
              <table class="table main-table table-hover">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Course Name</th>
                    <th scope="col">Course Description</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((items, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{items.course_name}</td>
                        <td>{items.course_desc}</td>
                        <td className="table_layout_btn ">
                          <button
                            className="btn  table__btn table_btn3_color"
                            onClick={() => {
                              navigate(`/admin-content/${items.course_path}`);

                              //   openUpdateModal();
                            }}
                          >
                            Create Content
                          </button>
                          <button
                            className="btn  table__btn table_btn1_color"
                            onClick={() => {
                              // navigate(`/user-edit-info/${items.id}`);
                              // setsingdata(items);
                              openUpdateModal(items);
                            }}
                          >
                            Rename Course
                          </button>
                          <button
                            className="btn table_btn4_color table__btn"
                            onClick={() => {
                              // setId(items.id);
                              setId(items._id);

                              openModal();
                            }}
                          >
                            Delete Course
                          </button>

                          <button
                            className="btn  table__btn table_btn2_color"
                            onClick={() => {
                              navigate(
                                `/admin-course-content/${items.course_path}`
                              );
                              //   openUpdateModal();
                            }}
                          >
                            View
                          </button>

                          {/* <HiOutlineTrash
                            className="delsvg"
                            onClick={() => {
                              //   openModal();
                              //   setUid(items.id);
                            }}
                          /> */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              {/* <!-- Modal --> */}
              {modal ? (
                <section className="modal1__bg">
                  <div
                    class="modal-btn"
                    onClick={openModal}
                    // href="javascript:void(0)"
                    data-bs-toggle="modal"
                    data-bs-target=".watchvideomodal"
                  ></div>
                  <div className="modal1__align">
                    <div className="modal1__content" modal={modal}>
                      <div className="modal1__msg">
                        <h5>Are you sure you want to delete this Course? </h5>
                        <div className="modal1__btn_layout">
                          <button
                            className="btn btn-primary modal1__btn delete_btn_content"
                            onClick={() => {
                              deleteCourse();
                              openModal();
                            }}
                          >
                            {loader ? (
                              <i class="fa fa-circle-o-notch fa-spin mr-2"></i>
                            ) : (
                              ""
                            )}
                            Delete
                          </button>
                          <button
                            className="btn btn-primary modal1__btn "
                            onClick={() => {
                              openModal();
                            }}
                          >
                            {/* {loader ? (
                        <i class="fa fa-circle-o-notch fa-spin mr-4"></i>
                      ) : (
                        ""
                      )} */}
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              ) : null}
              {/* <!-- END MODAL --> */}

              {/* <!-- Update Modal --> */}
              {updatemodal ? (
                <section className="modal2__bg">
                  <div
                    class="modal-btn"
                    onClick={openUpdateModal}
                    // href="javascript:void(0)"
                    data-bs-toggle="modal"
                    data-bs-target=".watchvideomodal"
                  ></div>

                  <div className="modal2__align">
                    <div className="modal2__content" modal={modal}>
                      <div className="modal2__msg">
                        {alertRed ? (
                          <div class="modal-alert-msg ">{error}</div>
                        ) : (
                          ""
                        )}
                        <div className="mb-3">
                          <h6>Course Title</h6>
                          <div className="admin-input">
                            <input
                              value={courseName}
                              className="w-25  admin-input-content "
                              type="text"
                              name="text"
                              placeholder="Enter title for your Course"
                              onChange={(e) => setCourseName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="mb-5">
                          <h6>Course Description</h6>
                          <div className="admin-input">
                            <textarea
                              value={courseDesc}
                              className="w-50  admin-input-content "
                              type="text"
                              rows={5}
                              name="text"
                              placeholder="Enter Description for your Course"
                              onChange={(e) => setCourseDesc(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="modal2__btn_layout mt-5">
                          <button
                            className="btn  update_btn_content modal2__btn "
                            onClick={() => {
                              updateCourse();
                            }}
                          >
                            {loader ? (
                              <i class="fa fa-circle-o-notch fa-spin mr-2"></i>
                            ) : (
                              ""
                            )}
                            Update
                          </button>
                          <button
                            className="btn btn-primary modal2__btn "
                            onClick={openUpdateModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              ) : null}
              {/* <!-- END Update MODAL --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GetAlluser;
