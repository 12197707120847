import axios from "../axios";
import { create_course, fetch_courses } from "../api/apiRoutes";

export default {
  create_course: async (payload) => {
    const response = await axios.post(create_course, payload);
    return response;
  },
  fetch_courses: async () => {
    const response = await axios.get(fetch_courses);
    return response;
  },
};
