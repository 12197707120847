import React from "react";

const Course = () => {
  return (
    <section class="section services" id="pricing">
      <div class="container">
        <div class="row g-4">
          <div class="col-lg-12">
            <div class="text-center mb-5">
              <h3 class="heading">
                Our <span class="text-primary">Exclusive</span> Course
              </h3>
              <p>
                Learn how to analyze Stocks, Buy and Sell Stocks, write Covered
                Calls, read Charts and get an introduction into Options.
              </p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card">
              <div class="card-body">
                <div class="box">
                  <div class="flex-shrink-0 my-3">
                    <div class="avatar-sm align-items-center">
                      <div class="avatar-title">
                        <i class="mdi mdi-account-search"></i>
                      </div>
                    </div>
                  </div>
                  <h4>Stock Analysis</h4>
                  {/* <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Excepturi, quae?
                </p> */}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card">
              <div class="card-body">
                <div class="box">
                  <div class="flex-shrink-0 my-3">
                    <div class="avatar-sm align-items-center">
                      <div class="avatar-title">
                        <i class="mdi mdi-account-eye"></i>
                      </div>
                    </div>
                  </div>
                  <h4>Reading Charts</h4>
                  {/* <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Excepturi, quae?
                </p> */}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card">
              <div class="card-body">
                <div class="box">
                  <div class="flex-shrink-0 my-3">
                    <div class="avatar-sm align-items-center">
                      <div class="avatar-title">
                        <i class="mdi mdi-arrow-left-right"></i>
                      </div>
                    </div>
                  </div>
                  <h4>Buy/Sell Stock</h4>
                  {/* <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Excepturi, quae?
                </p> */}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card">
              <div class="card-body">
                <div class="box">
                  <div class="flex-shrink-0 my-3">
                    <div class="avatar-sm align-items-center">
                      <div class="avatar-title">
                        <i class="mdi mdi-thumb-up-outline"></i>
                      </div>
                    </div>
                  </div>
                  <h4>Covered Calls</h4>
                  {/* <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Excepturi, quae?
                </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Course;
