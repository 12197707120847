import axios from "../axios";
import { create_content, delete_content, EDIT_CONTENT } from "../api/apiRoutes";

export default {
  create_content: async (payload) => {
    const response = await axios.post(create_content, payload);
    return response;
  },
  delete_content: async (payload) => {
    const response = await axios.get(`${delete_content}/${payload}`);
    return response;
  },

  edit_content: async (payload) => {
    const response = await axios.post(EDIT_CONTENT, payload);
    return response;
  },
};
