import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../css/style.css";
import "../css/materialdesignicons.min.css";
import "../css/swiper-bundle.min.css";
import "../scss/_menu.scss";
import "../scss/_pricing.scss";
import "../css/modal.css";

import axios from "../axios";
import { CONTACT_US_API } from "../api/apiRoutes";
import Footer from "../components/Homepage/footer";
import About from "../components/Homepage/aboutUs";
import Testimonials from "../components/Homepage/testimonials";
import Course from "../components/Homepage/exclusiveCourse";
import Training from "../components/Homepage/training";
const Homepage = () => {
  const navigate = useNavigate();
  
  const [alertRed, setAlertRed] = useState(false);
  const [error, setError] = useState("");
  const [alert, setAlert] = useState(false);
  const videoRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [navRes, setNav] = useState(false);
  const [showRegisterBtn, setShowRegisterBtn] = useState(false);
  const [showRegisterBottomStyle, setRegisterBottomStyle] = useState(false);

  const playVideo = () => {
    var video = document.getElementById("trainingVideo");
    video.play();
    document.getElementById("playButton").style.display = "none";
    document.getElementById("videoThumbnail").style.display = "none";
  };

  const openModal = () => {
    setModal(!modal);
  };
  const navShow = () => {
    setNav(!navRes);
    console.log("nav", navRes);
  };

  const submitContact = (e) => {
    e.preventDefault();
    let payload = {
      firstName,
      email,
      subject,
      message,
    };
    if (firstName && email && subject && message) {
      axios
        .post(CONTACT_US_API, payload)
        .then((d) => {
          setAlert(true);
          setTimeout(() => {
            setAlert(false);
          }, 2500);
          setError("Thanks For Your FeedBack");

          clearForm();
        })
        .catch(() => {
          setAlertRed(true);
          setTimeout(() => {
            setAlertRed(false);
          }, 2500);
          setError("Server Side Error");
          clearForm();
        });
    } else {
      setAlertRed(true);
      setTimeout(() => {
        setAlertRed(false);
      }, 2500);
      setError("Fill all the Required Fields**");
      // alert("FILL all the required fields");
    }
  };
  const clearForm = () => {
    setFirstName("");
    setEmail("");
    setSubject("");
    setMessage("");
  };

  const handleTimeUpdate = () => {
    if (
      videoRef.current.currentTime >= 209 &&
      videoRef.current.currentTime <= 214
    ) {
      setShowRegisterBtn(true);
    } else if (
      videoRef.current.currentTime >= 219 &&
      videoRef.current.currentTime <= 230
    ) {
      console.log("rehister button");
      setRegisterBottomStyle(true);
      setShowRegisterBtn(true);
    } else {
      setRegisterBottomStyle(false);
      setShowRegisterBtn(false);
    }
  };

  return (
    <div data-bs-spy="scroll" data-bs-target="#navbar" data-bs-offset="71">
      <div>
        <nav
          class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky"
          id="navbar"
        >
          <div class="container">
            {/* <!-- LOGO --> */}
            <a class=" text-uppercase">
              <img
                class="logo-light"
                src={require("../images/PNG-01.png")}
                alt=""
                height="85"
                //   width="150"
              />
            </a>

            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="true"
              aria-label="Toggle navigation"
              onClick={() => {
                navShow();
              }}
            >
              <span class="mdi mdi-menu"></span>
              {/* <!-- toggle button --> */}
            </button>

            <div
              class={`collapse navbar-collapse ${navRes ? "show" : ""}`}
              id="navbarCollapse"
            >
              <ul class="navbar-nav mx-auto" id="navbar-navlist">
                <li class="nav-item">
                  <a class="nav-link" href="#pricing">
                    Exclusive Course
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#testimonials">
                    Testimonials
                  </a>
                </li>

                <li class="nav-item">
                  <a class="nav-link" href="#about">
                    About us
                  </a>
                </li>

                <li class="nav-item">
                  <a class="nav-link mb-3 mb-lg-0" href="#contact">
                    Contact
                  </a>
                </li>
              </ul>
              {/* <!-- Button trigger modal --> */}
              <button
                class="btn btn-primary nav-btn"
                onClick={() => window.open("https://event.webinarjam.com/register/2/2ypk8cl") }//navigate("/free-webinar")}
              >
                Get Free Training
              </button>
              {/* <!-- Nav btn --> */}
              {/* <p
                class="text-primary sign-in"
                onClick={() => navigate("/sign-in")}
              >
                Login
              </p> */}
            </div>
          </div>
          {/* <!-- End container --> */}
        </nav>
      </div>
      {/* <!-- Start Home --> */}
      <section class=" home " id="home">
        <div class="">
          <div class="">
            <div class="">
              <div className="modal__video-style-div">
                {/* play 1st div */}

                {showRegisterBtn && (
                  <div
                    class={`overlayText ${
                      showRegisterBottomStyle
                        ? "accountTextSecond"
                        : "accountText  "
                    }`}
                    onClick={() => navigate("/free-webinar")}
                  >
                    <p
                      id="pauseText"
                      className="video-topText"
                      style={{
                        display: "block",
                        textAlign: "center",
                        padding: "0px 10px 0px 10px",
                      }}
                    >
                      <a
                        id="playButton"
                        style={{
                          width: "5vw",
                        }}
                        class="play-video-btn-svg"
                        // onClick={() => playVideo()}
                      ></a>
                      <span>REGISTER NOW</span>
                    </p>
                  </div>
                )}

                {/* play 2nd div */}
                <div id="videoThumbnail" class="overlayText">
                  {/* <p id="topText">Watch a Short 3 minute training video</p> */}
                  <p
                    id="pauseText"
                    className="video-topText"
                    style={{ textAlign: "center" }}
                  >
                    <a
                      style={{
                        width: "5vw",
                      }}
                      class="play-video-btn-svg"
                      onClick={() => playVideo()}
                      // href="javascript:void(0)"
                    >
                      <span class="avatar-sm">
                        <div
                          class="avatar-title rounded-circle btn-icon curs_p"
                          style={
                            {
                              // backgroundColor: "#ffffff47",
                              // color: "#ffffff90",
                            }
                          }
                        >
                          <i class="mdi mdi-play"></i>
                        </div>
                      </span>
                    </a>
                    <span style={{ marginLeft: "6vw" }}>
                      Watch Training Video
                    </span>
                  </p>
                </div>
                <video
                  ref={videoRef}
                  onTimeUpdate={handleTimeUpdate}
                  id="trainingVideo"
                  poster=""
                  className="modal__video-style"
                  src="https://successstockmarket.com/videos/ARDEDU%20Final.mp4"
                  controls="controls"
                  autopl
                  ay="true"
                  onPause={() => {}}
                  onPlay={() => {
                    document.getElementById("videoThumbnail").style.display =
                      "none";
                  }}
                  onEnded={() => {
                    document.getElementById("pauseText").style.display =
                      "block";
                    document.getElementById("playButton").style.display =
                      "block";
                  }}
                />
              </div>
            </div>
            {/* <!-- end col--> */}
          </div>
          {/* <!-- end row--> */}
        </div>

        {/* <!--end container--> */}
      </section>
      {/* <!-- End Home --> */}

      <div className="text-center mt-4">
        <p>
          <span
            className="text-primary clickHere"
            onClick={() => navigate("/free-webinar")}
          >
            Click here
          </span>{" "}
          to register for the live class
        </p>
      </div>
      {/* <!-- start Course --> */}
      <Course />
      {/* <!-- end Course --> */}

      {/* <!-- start training --> */}
      <Training />
      {/* <!-- end training --> */}

      {/* <!--start testimonials--> */}
      <Testimonials />
      {/* <!--end testimonials--> */}

      {/* <!-- start about --> */}
      <About />
      {/* <!-- end about --> */}

      {/* <!-- start contact --> */}

      <section class="section contact" id="contact">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="text-center mb-5">
                <h3 class="heading mb-3">
                  <span class="text-primary">Contact </span>Us
                </h3>

                {/* <p class="text-muted mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p> */}
              </div>
            </div>
            {/* <!--end col--> */}
          </div>
          <div class="row justify-content-around g-4">
            <div class="col-lg-6">
              {alertRed ? <div class="font-alert-c">{error}</div> : ""}
              {alert ? <div class="font-alert-c-g">{error}</div> : ""}
              <form
                method="post"
                onSubmit={submitContact}
                class="contact-form"
                name="myForm"
                id="myForm"
              >
                <span id="error-msg"></span>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="position-relative mb-3">
                      <span class="input-group-text">
                        <i class="mdi mdi-account-outline"></i>
                      </span>
                      <input
                        name="name"
                        value={firstName}
                        id="name"
                        type="text"
                        class="form-control"
                        placeholder="Enter your name*"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="position-relative mb-3">
                      <span class="input-group-text">
                        <i class="mdi mdi-email-outline"></i>
                      </span>
                      <input
                        name="email"
                        value={email}
                        id="email"
                        type="email"
                        class="form-control"
                        placeholder="Enter your email*"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="position-relative mb-3">
                      <span class="input-group-text">
                        <i class="mdi mdi-file-document-outline"></i>
                      </span>
                      <input
                        value={subject}
                        name="subject"
                        id="subject"
                        type="text"
                        class="form-control"
                        placeholder="Subject"
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="position-relative mb-3">
                      <span class="input-group-text align-items-start">
                        <i class="mdi mdi-comment-text-outline"></i>
                      </span>
                      <textarea
                        name="comments"
                        value={message}
                        id="comments"
                        rows="4"
                        class="form-control"
                        placeholder="Enter your message*"
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <input
                      type="submit"
                      id="submit"
                      name="send"
                      class="btn btn-primary"
                      value="Send Message"
                    />
                  </div>
                </div>
              </form>
              {/* <!--end form--> */}
            </div>
            {/* <!--end col--> */}
            <div class="col-lg-4">
              <div class="contact-details mb-4 mb-lg-0">
                <p class="mb-3">
                  <i class="mdi mdi-email-outline align-middle text-muted fs-20 me-2"></i>{" "}
                  <span class="fw-medium">info@stockmarket-edu.com</span>
                </p>
                <p class="mb-3">
                  <i class="mdi mdi-web align-middle text-muted fs-20 me-2"></i>{" "}
                  <span class="fw-medium">www.ardedu.com</span>
                </p>
                <p class="mb-3">
                  <i class="mdi mdi-phone align-middle text-muted fs-20 me-2"></i>{" "}
                  <span class="fw-medium">239-823-5070</span>
                </p>
                {/* <p class="mb-3">
                  <i class="mdi mdi-hospital-building text-muted fs-20 me-2"></i>{" "}
                  <span class="fw-medium">9:00 AM - 6:00 PM</span>
                </p> */}
                <p class="mb-3">
                  <i class="mdi mdi-map-marker-outline text-muted fs-20 me-2"></i>{" "}
                  <span class="fw-medium">
                    6338 Presidential Court #204 Ft. Myers Florida 33919
                  </span>
                </p>
              </div>
              {/* <!--end contact-details--> */}
            </div>
            {/* <!--end col--> */}
          </div>
          {/* <!--end row--> */}
        </div>
        {/* <!--end container--> */}
      </section>
      {/* <!-- end contact --> */}

      {/* <!-- FOOTER-ALT --> */}
      <Footer />
      {/* <!-- END FOOTER-ALT --> */}
    </div>
  );
};
export default Homepage;
