import axios from "../axios";

import {
  SIGN_IN,
  INITIAL_SIGN_UP,
  SIGN_UP,
  FORGET_PASSWORD,
  UPDATE_PASSWORD,
  verify_recaptcha,
  CHANGE_PASSWORD,
  GET_USER_DATA,
  UPDATE_USER_DATA,
  TRAINING_REGISTER,
} from "../api/apiRoutes";

export default {
  userInitialSigin: async (payload) => {
    console.log("payload", payload);
    const response = await axios.post(INITIAL_SIGN_UP, payload);
    console.log("response", response);
    return response;
  },
  userSignUp: async (payload) => {
    const response = await axios.post(SIGN_UP, payload);
    return response;
  },
  userSignIn: async (payload) => {
    // console.log("payload", payload);
    const response = await axios.post(SIGN_IN, payload);
    return response;
  },

  trainingRegister: async (payload) => {
    console.log("payload", payload);
    const response = await axios.post(TRAINING_REGISTER, payload);
    return response;
  },

  forgetPassword: async (payload) => {
    const response = await axios.post(FORGET_PASSWORD, payload);
    return response;
  },
  resetPassword: async (payload) => {
    const response = await axios.post(UPDATE_PASSWORD, payload);
    return response;
  },
  changePassword: async (payload) => {
    const response = await axios.post(CHANGE_PASSWORD, payload);
    return response;
  },
  getUserData: async () => {
    const reponse = await axios.get(GET_USER_DATA);
    return reponse;
  },
  updateUserData: async (payload) => {
    const response = await axios.post(UPDATE_USER_DATA, payload);
    return response;
  },
  verifyRecaptcha: async (payload) => {
    const response = await axios.post(verify_recaptcha, payload);
    return response;
  },
};
