import React from "react";

const Testimonials = () => {
  return (
    <section class="section testimonials" id="testimonials">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-lg-12">
            <div class="text-center ">
              <h3 class="heading">
                <span class="text-primary">Testimonials</span>
              </h3>
              {/* <p>
            Nam egestas mi sit amet orci dignissim tempor Integer
            tincidunt nunc mauris in egestas nibh.
          </p> */}
            </div>
          </div>
        </div>
        {/* <!--end row--> */}
        <div class="row">
          <div class="col-lg-12">
            <div class="swiper mySwiper1 my-5">
              <div class="flex">
                <div class=" client-box card mb-6">
                  <div class="client-content card-body p-0">
                    <div class="quote-img">
                      <img
                        src={require("../../../images/quote.png")}
                        alt="quote"
                        class="img-fluid"
                      />
                    </div>
                    <p class="text-dark mb-0 fs-5 lh-base">
                      Your Incometrader Newsletter truly is a must for all
                      investors. I have used it for a while now and start my
                      trading off each week with your newsletter in hand. I am
                      not even sure how I would proceed without it.
                    </p>
                  </div>
                  {/* <!--end client-content--> */}
                  <div class="d-flex align-items-center mt-4 pt-3">
                    {/* <img
                      src={require("../../../images/img-1.jpg")}
                      alt=""
                      height="55"
                      width="55"
                      class="rounded-circle"
                    /> */}
                    <div class="ms-2">
                      <h5 class="mb-0">Jerry Delaney</h5>
                      <p class="text-muted mb-0">Chicago, IL</p>
                    </div>
                  </div>
                </div>
                <div class=" client-box card  ">
                  <div class="client-content card-body p-0">
                    <div class="quote-img">
                      <img
                        src={require("../../../images/quote.png")}
                        alt="quote"
                        class="img-fluid"
                      />
                    </div>
                    <p class="text-dark mb-0 fs-5 lh-base">
                      Sometimes I disagree with one of your trading candidates
                      or your weekly commentary but I can't put your newsletter
                      down. In fact it seems that the stronger I disagree with a
                      comment or pick the more often you are correct. I'm still
                      learning but someday I hope to get it right.
                    </p>
                  </div>
                  {/* <!--end client-content--> */}
                  <div class="d-flex align-items-center mt-4 pt-3">
                    {/* <img
                      src={require("../../../images/img-2.jpg")}
                      alt=""
                      height="55"
                      width="55"
                      class="rounded-circle"
                    /> */}
                    <div class="ms-2">
                      <h5 class="mb-0">Derrick Nelson</h5>
                      <p class="text-muted mb-0">Marshall, MN</p>
                    </div>
                  </div>
                </div>
                <div class=" client-box card  ">
                  <div class="client-content card-body p-0">
                    <div class="quote-img">
                      <img
                        src={require("../../../images/quote.png")}
                        alt="quote"
                        class="img-fluid"
                      />
                    </div>
                    <p class="text-dark mb-0 fs-5 lh-base">
                      I have 8 years of extended education past the high school
                      level and have to ask myself why is this not taught in
                      school. If you and your staff ever decide to open up a
                      school I will be first in line to enroll my kids.
                    </p>
                  </div>
                  {/* <!--end client-content--> */}
                  <div class="d-flex align-items-center mt-4 pt-3">
                    {/* <img
                      src={require("../../../images/img-3.jpg")}
                      alt=""
                      height="55"
                      width="55"
                      class="rounded-circle"
                    /> */}
                    <div class="ms-2">
                      <h5 class="mb-0">F. Tonner</h5>
                      <p class="text-muted mb-0">Hartsville, TN</p>
                    </div>
                  </div>
                </div>
                {/* <!--end client-box--> */}

                {/* <!--end client-box--> */}
              </div>
            </div>
            {/* <!--end widget-slider--> */}
          </div>
          {/* <!--end col--> */}
        </div>
        {/* <!--end row--> */}
      </div>
      {/* <!--end container--> */}
    </section>
  );
};

export default Testimonials;
