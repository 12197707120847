import "../../../css/style.css";
import "../../../css/materialdesignicons.min.css";
import "../../../css/swiper-bundle.min.css";
import "../../../scss/_menu.scss";
import "../../../scss/_pricing.scss";
import "../../../css/modal.css";
import IncomeTraderCoveredCall from "../../../video/IncomeTraderCoveredCall.mp4";
import IncomeTraderTour from "../../../video/IncomeTraderTour.mp4";
import { useRef } from "react";
const PriorVideos = () => {
  const videoRef = useRef(null);
  return (
    <div class="container">
      <div class="text-center mb-5">
        <h3 class="heading mb-3">
          <span class="text-primary"> PLEASE WATCH BOTH </span>VIDEOS PRIOR TO
          CLASS
        </h3>
      </div>

      <div style={{ marginBottom: "30px" }}>
        <p>
          <b>Video 1)</b>
        </p>
        <p>
          Cash flow Quick Start Most investors do not realize that owning a
          mutual fund is actually a costly investment. With Front loaded fees,
          back loaded fees, quarterly 12B-1 fees, rebalancing fees and even
          performance fees, it's no wonder individual investors have a hard time
          making any profit. And yet, if we treated our investment portfolio the
          same way we treat real estate we would start to see an immediate
          profit. Covered Calls are a simple solution that any investor can
          start to use for monthly income. By renting out your stocks, other
          investors will pay you for the right to buy your stock at a higher
          price, thereby guaranteeing you a monthly source of income each and
          every month for as long as you own that stock. This is monthly income
          from your portfolio without ever having to draw down the principle.
        </p>
        <div>
          <video
            ref={videoRef}
            id="trainingVideo"
            poster=""
            className="modal__video-style"
            src={IncomeTraderCoveredCall}
            controls="controls"
          />
          {/* <iframe
            width="100%"
            height="423"
            src="https://www.youtube.com/embed/sK5Fy64idVU"
            title="IncomeTrader CoveredCall"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe> */}
        </div>
      </div>
      <div>
        <p>
          <b>Video 2)</b>
        </p>
        <p>
          Website Tour Take a tour of the Invest-Edu website and find out how
          these proprietary tools will make you a better, more confident
          investor. These tools were designed to quickly and easily find a stock
          to invest in less than 30 seconds. It has taken us decades to get this
          far and now all the power of the Invest-Edu website is available for
          you and your family to use and profit from. Commentary, Stock, Covered
          Call and Spread picks from our editorial staff, Homework examples,
          Investor Psychology articles and of course our highly sought after
          search models are just a few of the things that make this site so
          powerful. Easily find only the best stocks fundamentally that you can
          buy at severely discounted prices so you can write covered calls. You
          can do that here and even see them ranked from highest returns to the
          lowest, all with just one simple click. You cannot do that on any
          other financial website!
        </p>
        <div>
        <video
            ref={videoRef}
            id="trainingVideo"
            poster=""
            className="modal__video-style"
            src={IncomeTraderTour}
            controls="controls"
          />
          {/* <iframe
            width="100%"
            height="423"
            src="https://www.youtube.com/embed/UF3d2xsEUug"
            title="IncomeTrader Tour"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe> */}
        </div>
      </div>
    </div>
  );
};
export default PriorVideos;
