import "../../../css/style.css";
import "../../../css/materialdesignicons.min.css";
import "../../../css/swiper-bundle.min.css";
import "../../../scss/_menu.scss";
import "../../../scss/_pricing.scss";
import "../../../css/modal.css";
import expostocksintro from "../../../video/expostocksintro.mp4";
import { useRef } from "react";
const ExpoStocksVideo = () => {
  const videoRef = useRef(null);
  return (
    <div className="modal__video-style-div">
      {/* <iframe
        width="100%"
        height="600px"
        src="https://www.youtube.com/embed/L06Wq4arP4o"
        title="expo stocks intro"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe> */}
      <video
        ref={videoRef}
        id="trainingVideo"
        poster=""
        className="modal__video-style"
        src={expostocksintro}
        controls="controls"
      />
    </div>
  );
};
export default ExpoStocksVideo;
