import React, {useEffect, useState} from "react";
import "../../../../css/style.css";
import {useNavigate} from "react-router-dom";
import "../../../../css/formSignin.css";
import userApi from "../../../../services/userApi";
import Footer from "../../../../components/footer";
import AuthNav from "../../../../components/navbar/authNav";
import eventApi from "../../../../services/eventApi";
// import tick from './'
// import ReCAPTCHA from "react-google-recaptcha";

const TrainingRegister = () => {
  useEffect(() => {
    // if (localStorage.getItem("isAuthenticated")) {
    //   navigate("/course");
    // }
    handleFetchAllEvents();
    // if()
    if(window.fbq){
      console.log("fbq track register")
      window.fbq('track', 'PageView');
    }
  }, []);
  const navigate = useNavigate();
  //   const [confirmpassword, setconfirmpassword] = useState("");
  const [email, setemail] = useState("");
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [phone, setphone] = useState("");
  const [events, setEvents] = useState([]);
  const [eventInfo, setEventInfo] = useState({});
  const [isVerified, setIsVerified] = useState(false);
  const [showpass, setpass] = useState(false);
  const [loader, setLoader] = useState(false);
  // const [alertRed, setAlertRed] = useState(false);
  const [error, setError] = useState(false);
  const [formMessage, setFormMessage] = useState(false);

  const [succesfullyRegistered, setSuccesfullyRegistered] = useState(false);
  const setemailHandler = (e) => {
    setemail(e);
  };
  const setfnameHandler = (e) => {
    setfname(e);
  };
  const setlnameHandler = (e) => {
    setlname(e);
  };
  const setphoneHandler = (e) => {
    setphone(e);
  };
  const handleRecaptchaEmail = async (token) => {
    try {
      const payload = {
        token: token,
      };
      const response = await userApi.verifyRecaptcha(payload);
      // console.log("response", response);
      if (response.data.success) {
        setIsVerified(true);
      }
      // alert(response.data.message);
    } catch {
      setIsVerified(true);
      // alert("err");
    }
  };
  const training_register = async () => {
    const values = {
      firstname: fname,
      lastname: lname,
      email: email,
      phone: phone,
      eventInfo: eventInfo,
    };
    setLoader(true);
    console.log("value", values);
    // console.log("hdsjhsdjhs");
    // alert("wdssd");
    try {
      if (fname && lname && email && phone && eventInfo.event_link) {
        let resp = await userApi.trainingRegister(values);
        // console.log("response==", resp);
        setLoader(false);

        if (resp.data.success) {
          console.log("response==", resp.data);
          setlname("");
          setfname("");
          setemail("");
          setphone("");
          setEventInfo("");

          setError(false);
          setSuccesfullyRegistered(true);
          return;
        }
        setError(true);
        setFormMessage(resp.data.message);

        // if (resp.data.success) {
        //   setlname("");
        //   setfname("");
        //   setemail("");
        //   setphone("");
        //   setEventInfo("");
        //   setLoader(false);
        //   setAlert(true);
        //   setTimeout(() => {
        //     setAlert(false);
        //     navigate("/");
        //   }, 1000);
        //   setError("Successfully Registered");
        // } else {
        //   setlname("");
        //   setfname("");
        //   setemail("");
        //   setphone("");
        //   setEventInfo("");
        //   setLoader(false);
        //   setAlertRed(true);
        //   setTimeout(() => {
        //     setAlertRed(false);
        //   }, 5000);
        //   setError(resp.data.message);
        // }
      } else {
        // setlname("");
        // setfname("");
        // setemail("");
        // setphone("");
        // setAlertRed(true);
        setLoader(false);
        // setTimeout(() => {
        //   setAlertRed(false);
        // }, 2000);
        setError(true);
        setFormMessage("All fields are required.");
      }
    } catch (err) {
      // setlname("");
      // setfname("");
      // setemail("");
      // setphone("");
      // setEventInfo("");
      // setAlertRed(true);
      setLoader(false);
      // setTimeout(() => {
      //   setAlertRed(false);
      // }, 2000);
      setError(true);
      setFormMessage(err.message);
    }
  };
  const handleFetchAllEvents = async () => {
    try {
      const response = await eventApi.fetchEvents();
      if (response.data.success) {
        setEvents(response.data.payload);
      }
    } catch (err) {
      console.log("Error", err.message);
      setError(true);
      setFormMessage(`Server Error: Please check your internet connection`);
    }
  };
  return (
    <div>
      <AuthNav />
      <div className="main-reg">
        <div className="register-photoS ">
          {succesfullyRegistered ? (
            <div class="card auth-box form-container">
              <div class="py-5">
                <div class="mt-4 pt-4 text-center">
                  <div
                    class="alert alert-success text-center m-4 pb-4"
                    role="alert"
                  >
                    <img
                      class="logo-light"
                      src={require("../../../../images/tick-logo.png")}
                      // src={require("../images/tick-logo.png")}
                      alt=""
                      height="64"
                      //   width="150"
                    />
                    <h2>Congratulations!</h2> You have successully registered
                    for the event.
                  </div>
                  <p>
                    <a href="https://ardedu.com" class="text-muted">
                      &lt; Go back
                    </a>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="form-container">
              <div className="image-holder"></div>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="">
                  <div className="mb-4">
                    <h3 className="headingR">
                      Register for a Free <br />
                      <span className="text-primary">
                        60 minute Live Training{" "}
                      </span>
                    </h3>
                  </div>
                </div>
                {/* <a className="already5 ">
                Fill up the following form to get 60 minutes training video.
              </a> */}
                {formMessage != "" ? (
                  <div
                    className={`alert ${error ? "alert-danger" : "alert-info"}`}
                  >
                    {formMessage}
                  </div>
                ) : (
                  ""
                )}
                <div className="form-group">
                  <input
                    value={fname}
                    className="form-control"
                    type="text"
                    name="fname"
                    placeholder="First Name"
                    onChange={(e) => setfnameHandler(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    value={lname}
                    className="form-control"
                    type="text"
                    name="lname"
                    placeholder="Last Name"
                    onChange={(e) => setlnameHandler(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    value={email}
                    autoComplete="new-password"
                    className="form-control"
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => setemailHandler(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    value={phone}
                    className="form-control"
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    onChange={(e) => setphoneHandler(e.target.value)}
                  />
                </div>

                {/* <ReCAPTCHA
                sitekey="6Le-F-kkAAAAAHud51G14kq3Clh3AEgYgdQghoOV"
                onChange={(data) => handleRecaptchaEmail(data)}
                onExpired={() => setIsVerified(false)}
              /> */}
                <h6 className="headingSm">Choose a class</h6>

                {events &&
                  events.length > 0 &&
                  events.map((event, index) => {
                    if (event.event_title !== "No Event")
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                          }}
                          onClick={() => {
                            // console.log("event from div", event);
                            setEventInfo(event);
                          }}
                        >
                          <div>
                            <input
                              onChange={() => {
                                // console.log("event from input==>", event);
                                setEventInfo(event);
                              }}
                              checked={eventInfo._id == event._id}
                              type="radio"
                              id="myRadio"
                              name="myRadio"
                              style={{ width: "10px", cursor: "pointer" }}
                            />
                          </div>
                          <div className="mt-1">
                            <label
                              className="ml-2"
                              id="myRadio"
                              name="myRadio"
                              style={{ cursor: "pointer" }}
                            >
                              <span>
                                {event.event_date} <br />
                                {/* <b> {moment(event.event_date).format("MM/DD")}</b> */}
                              </span>
                              <span className="">
                                <b>{event.event_title}</b>
                              </span>
                            </label>
                          </div>
                        </div>
                      );
                    else {
                      return;
                    }
                  })}
                {/* 
              <label id="myRadio" name="myRadio">
                Event
              </label>
              <input type="radio" id="myRadio" name="myRadio" /> */}

                <div className=" row justify-content-center ">
                  <button
                    className="btn btn-primary"
                    onClick={training_register}
                  >
                    {loader ? (
                      <i className="fa fa-circle-o-notch fa-spin mr-3"></i>
                    ) : (
                      ""
                    )}
                    Get Free Access
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

//  #bfc6ca
export default TrainingRegister;
