import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import userApi from "../../../services/userApi";
import Navbar from "../../../components/navbar/navbar";
import "../../../css/style.css";
import courseApi from "../../../services/courseApi";
import "../../../css/admin.css";
import "../../../css/formSignin.css";
import AdminNav from "../../../components/navbar/adminNav";
import { v1 as uuidv1 } from "uuid";
// import "../../css/style.css";

const Admin_Course = () => {
  const navigate = useNavigate();
  const [courseName, setCourseName] = useState("");
  const [coursePath, setCoursePath] = useState("");
  const [courseDesc, setCourseDesc] = useState("");
  const [alertRed, setAlertRed] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [alert, setAlert] = useState(false);

  const handleCreateCourse = async (e) => {
    e.preventDefault();
    if (!courseName || !courseDesc) {
      setAlertRed(true);
      setTimeout(() => {
        setAlertRed(false);
      }, 2000);
      setError("All fields are required");
      // alert("All fields are required");
      return;
    }
    try {
      const payload = {
        course_name: courseName,
        course_path: uuidv1(), //courseName.toLowerCase().replaceAll(" ", "_"),
        course_desc: courseDesc,
      };
      setLoader(true);
      const response = await courseApi.create_course(payload);
      if (response.data.success) {
        setLoader(false);
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 2000);
        setError(response.data.message);
        // alert(response.data.message);
        setCourseName("");
        setCoursePath("");
        setCourseDesc("");
      }
    } catch (err) {
      setLoader(false);
      setAlertRed(true);
      setTimeout(() => {
        setAlertRed(false);
      }, 2000);

      setError(err.message);
      // alert(err.message + " course already exists");
      setCourseName("");
      setCoursePath("");
      setCourseDesc("");
    }
  };

  return (
    <div>
      <AdminNav />
      <div className="m-4 ">
        <div className="admin">
          <div class=" mb-4">
            <h4>Create a Course</h4>
            {/* <h3 class="heading">
              Admin <span class="text-primary">Panel</span>
            </h3> */}
            {/* <p>Need Help With ? Please Call 239-823-5070</p> */}
          </div>
          <div className="admin-alert">
            {alert ? <div class="font-alert3">{error}</div> : ""}

            {alertRed ? (
              <div class="font-alert1">
                <strong className="mr-2"></strong> {error}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-4">
            <h6>Course Title</h6>
            <div className="admin-input">
              <input
                value={courseName}
                className="w-25  admin-input-content "
                type="text"
                name="text"
                placeholder="Enter title for your Course"
                onChange={(e) => setCourseName(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-4">
            <h6>Course Description</h6>
            <div className="admin-input">
              <textarea
                value={courseDesc}
                className="w-50  admin-input-content "
                type="text"
                rows={5}
                name="text"
                placeholder="Enter Description for your Course"
                onChange={(e) => setCourseDesc(e.target.value)}
              />
            </div>
          </div>
          <div className="admin_btn_layout mt-5">
            {/* <p
              className="text-primary admin-btn-course "
              onClick={() => {
                navigate("/admin-content");
              }}
            >
              Back to Course-Content
            </p> */}

            <button
              className="btn btn-primary admin-btn mt-5"
              onClick={handleCreateCourse}
            >
              {loader ? <i class="fa fa-circle-o-notch fa-spin mr-4"></i> : ""}
              Save Course
            </button>
          </div>
          {/* <p
            className="text-primary admin-btn-course "
            onClick={() => {
              navigate("/user-mangement");
            }}
          >
            Back to User-Management
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Admin_Course;
