import axios from "../axios";
import { GET_COURSES_DATA, GET_CONTENT_DATA } from "../api/apiRoutes";

export default {
  getCoursesData: async () => {
    const reponse = await axios.get(GET_COURSES_DATA);
    return reponse;
  },
  getContentData: async (id) => {
    console.log("ID", id);
    const reponse = await axios.get(`${GET_CONTENT_DATA}/${id}`);
    return reponse;
  },
};
