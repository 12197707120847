import { API_LIST } from "./config";

const domain_prefix = API_LIST.userApi;
//user
export const SIGN_UP = domain_prefix + "v1/auth/register";
export const INITIAL_SIGN_UP = domain_prefix + "v1/auth/initial-signup";
export const SIGN_IN = domain_prefix + "v1/auth/login";
export const FORGET_PASSWORD = domain_prefix + "v1/auth/forgot-password";
export const UPDATE_PASSWORD = domain_prefix + "v1/auth/reset-password";
export const CHANGE_PASSWORD = domain_prefix + "v1/auth/change-password";
export const GET_USER_DATA = domain_prefix + "v1/auth/get-user-data";
export const UPDATE_USER_DATA = domain_prefix + "v1/auth/update-user-info";
export const CONTACT_US_API = domain_prefix + "v1/auth/contactUs";
export const INCOME_TRADER_REGISTER = domain_prefix + "v1/auth/income-trader-register";

export const TRAINING_REGISTER = domain_prefix + "v1/auth/register-training";
export const verify_recaptcha = domain_prefix + "v1/admin/verify-recaptcha";

//admin
export const ADMIN_SIGN_UP = domain_prefix + "v1/admin/admin-register";
export const ADMIN_SIGN_IN = domain_prefix + "v1/admin/admin-login";
export const GET_ADMIN_DATA = domain_prefix + "v1/admin/get-user-data";
export const GET_ALL_USER_DATA = domain_prefix + "v1/admin/get-all-users";
export const DELETE_USER = domain_prefix + "v1/admin/delete-user";
export const FIND_USER = domain_prefix + "v1/admin/find-user";
export const UPDATE_USER_DETAIL = domain_prefix + "v1/admin/update-user";
export const DELETE_COURSE = domain_prefix + "v1/courses/delete-course";
export const DELETE_CONTENT = domain_prefix + "v1/content/delete-content";
export const EDIT_CONTENT = domain_prefix + "v1/content/edit-content";

//courses
export const create_course = domain_prefix + "v1/courses/create-course";
export const fetch_courses = domain_prefix + "v1/courses/get-all-courses";
export const GET_COURSES_DATA = domain_prefix + "v1/courses/get-all-courses";
export const update_course = domain_prefix + "v1/courses/edit-course";

//content
export const create_content = domain_prefix + "v1/content/create-content";
export const GET_CONTENT_DATA = domain_prefix + "v1/content/fetch-content";
export const delete_content = domain_prefix + "v1/content/delete-content";


//Events
export const fetch_events = domain_prefix + "v1/event/get-all-events";
export const delete_event = domain_prefix + "v1/event/delete-event";
export const update_event = domain_prefix + "v1/event/edit-event";
export const create_event = domain_prefix + "v1/event/create-event";
export const find_event = domain_prefix + "v1/event/find-event";

