import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import userApi from "../../../services/userApi";
import Navbar from "../../../components/navbar/navbar";
import "../../../css/style.css";
import courseApi from "../../../services/courseApi";
import "../../../css/admin.css";
import "../../../css/formSignin.css";
import AdminNav from "../../../components/navbar/adminNav";
import { v1 as uuidv1 } from "uuid";
import eventApi from "../../../services/eventApi";
// import "../../css/style.css";

const Create_Event = () => {
  const navigate = useNavigate();

  const [eventName, setEventName] = useState("");
  const [eventLink, setEventLink] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventDesc, setEventDesc] = useState("");

  const [alertRed, setAlertRed] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [alert, setAlert] = useState(false);

  const handleCreateEvent = async (e) => {
    e.preventDefault();
    if (!eventName || !eventLink || !eventDate || !eventDesc) {
      setAlertRed(true);
      setTimeout(() => {
        setAlertRed(false);
      }, 5000);
      setError("All fields are required");
      return;
    }
    try {
      const payload = {
        event_title: eventName,
        event_desc: eventDesc,
        event_link: eventLink,
        event_date: eventDate,
      };
      setLoader(true);
      const response = await eventApi.createEvent(payload);
      if (response.data.success) {
        setLoader(false);
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 5000);
        setError(response.data.message);
        setEventName("");
        setEventLink("");
        setEventDate("");
        setEventDesc("");
      }
    } catch (err) {
      setLoader(false);
      setAlertRed(true);
      setTimeout(() => {
        setAlertRed(false);
      }, 5000);

      setError(err.message);
      setEventName("");
      setEventLink("");
      setEventDate("");
      setEventDesc("");
    }
  };

  return (
    <div>
      <AdminNav />
      <div className="m-4 ">
        <div className="admin">
          <div class=" mb-4">
            <h4>Create an Event</h4>
          </div>
          <div className="admin-alert">
            {alert ? <div class="font-alert3">{error}</div> : ""}

            {alertRed ? (
              <div class="font-alert1">
                <strong className="mr-2"></strong> {error}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-4">
            <h6>Event Title</h6>
            <div className="admin-input">
              <input
                value={eventName}
                className="w-25  admin-input-content "
                type="text"
                name="text"
                placeholder="Enter title for your Course"
                onChange={(e) => setEventName(e.target.value)}
              />
            </div>
          </div>

          <div className="mb-4">
            <h6>Event Link</h6>
            <div className="admin-input">
              <input
                value={eventLink}
                className="w-25  admin-input-content "
                type="text"
                name="text"
                placeholder="Enter title for your Course"
                onChange={(e) => setEventLink(e.target.value)}
              />
            </div>
          </div>

          <div className="mb-4">
            <h6>Event Date</h6>
            <div className="admin-input">
              <input
                value={eventDate}
                className="w-25  admin-input-content "
                type="text"
                name="date"
                placeholder="Mar 19th Sun, 11am Eastern"
                onChange={(e) => setEventDate(e.target.value)}
              />
            </div>
          </div>

          <div className="mb-4">
            <h6>Event Description</h6>
            <div className="admin-input">
              <textarea
                value={eventDesc}
                className="w-50  admin-input-content "
                type="text"
                rows={4}
                name="text"
                placeholder="Enter Description for your Course"
                onChange={(e) => setEventDesc(e.target.value)}
              />
            </div>
          </div>
          <div className="admin_btn_layout mt-5">
            <button
              className="btn btn-primary admin-btn mt-5"
              onClick={handleCreateEvent}
            >
              {loader ? <i class="fa fa-circle-o-notch fa-spin mr-4"></i> : ""}
              Save Event
            </button>
          </div>
          {/* <p
            className="text-primary admin-btn-course "
            onClick={() => {
              navigate("/user-mangement");
            }}
          >
            Back to User-Management
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Create_Event;
