import AuthNav from "../../../components/navbar/authNav";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../../../css/style.css";
import "../../../css/materialdesignicons.min.css";
import "../../../css/swiper-bundle.min.css";
import "../../../scss/_menu.scss";
import "../../../scss/_pricing.scss";
import "../../../css/modal.css";
import { marketJson, trendMap } from "../../../api/jsonData";
import ThirdStep from "./ThirdStep";
import SecondStep from "./SecondStep";
import Result from "./Result";
import CurrencyInput from "react-currency-input-field";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import Footer from "../../../components/Homepage/footer";
const BusinessValue = () => {
  const [businessvalue, setBusinessValues] = useState({
    2023: {
      sales: 0,
      goods_sold: 0,
      operating_expenses: 0,
      officer_salary: 0,
      depreciation: 0,
      interest: 0,
      other_expenses: 0,
    },
    2022: {
      sales: 0,
      goods_sold: 0,
      operating_expenses: 0,
      officer_salary: 0,
      depreciation: 0,
      interest: 0,
      other_expenses: 0,
    },
    2021: {
      sales: 0,
      goods_sold: 0,
      operating_expenses: 0,
      officer_salary: 0,
      depreciation: 0,
      interest: 0,
      other_expenses: 0,
    },
  });
  const [result, setResult] = useState({});
  const years = [2023, 2022, 2021];
  const [earnings, setEarnings] = useState({
    owner_salary: 0,
    market_multiple_earning: 0,
  });
  const [riskFactors, setRiskFactors] = useState([]);
  const [earningTrend, setEarningTrend] = useState(
    "Steady Revenue, sustainable"
  );
  const resultRef = useRef(null);

  const handleBusinessCalculation = async (e, year) => {
    e.preventDefault();
    let result = {};
    years.map((y) => {
      const gross_profit =
        parseFloat(businessvalue[y].sales || 0) -
        parseFloat(businessvalue[y].goods_sold || 0);

      const netIncomeBeforeTax =
        gross_profit - parseFloat(businessvalue[y].operating_expenses || 0);

      const totalAddBlock =
        (parseFloat(businessvalue[y].officer_salary) || 0) +
        (parseFloat(businessvalue[y].other_expenses) || 0) +
        (parseFloat(businessvalue[y].depreciation) || 0) +
        (parseFloat(businessvalue[y].interest) || 0);

      const discretionaryEarnings =
        parseFloat(netIncomeBeforeTax) +
        parseFloat(totalAddBlock) +
        parseFloat(earnings.owner_salary || 0);

      const adjustedEbitda =
        parseFloat(discretionaryEarnings) -
        parseFloat(earnings.owner_salary || 0);

      let _businessResult =
        parseFloat(adjustedEbitda) *
        parseFloat(earnings.market_multiple_earning || 0);

      const _riskfactorValue =
        trendMap[earningTrend][riskFactors?.length || 0] || 0;

      const calcResult = (
        parseFloat(_businessResult) +
        parseFloat((_riskfactorValue * _businessResult) / 100)
      ).toFixed(2);

      result[y] = {
        calcResult: calcResult,
        gross_profit: gross_profit,
        netIncomeBeforeTax: netIncomeBeforeTax,
        totalAddBlock: totalAddBlock,
        discretionaryEarnings: discretionaryEarnings,
        adjustedEbitda: adjustedEbitda,
      };
    });
    setResult(result);
    setTimeout(() => {
      if (resultRef.current) {
        resultRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 500);
  };

  const handleInputChange = (e, year) => {
    // const { name, value } = e.target
    const { name, value } = e;
    setBusinessValues((prevValues) => ({
      ...prevValues,
      [year]: {
        ...prevValues[year],
        [name]: value === "" ? 0 : value,
      },
    }));
  };

  const handleChangeMultipleEarning = (e) => {
    setEarnings({
      ...earnings,
      // [e.target.name]: e.target.value == "" ? 0 : e.target.value,
      [e.name]: e.value == "" ? 0 : e.value,
    });
  };

  const handleRiskCheckboxChange = (e) => {
    if (e.target.checked) {
      setRiskFactors([...riskFactors, e.target.value]);
    } else {
      const _risks = riskFactors.filter((item) => item !== e.target.value);
      setRiskFactors(_risks);
    }
  };

  const handleEarningTrendRadioChange = (value) => {
    setEarningTrend(value);
  };

  return (
    <div data-bs-spy="scroll" data-bs-target="#navbar" data-bs-offset="71">
      <AuthNav />

      <Tooltip
        place="bottom"
        variant="error"
        id="my-tooltip"
        style={{
          position: "absolute",
          zIndex: 999,
          maxWidth: "400px",
          backgroundColor: "#0F4369",
        }}
      />

      <div style={{ marginTop: "100px" }}>
        <section className="section contact " id="contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="text-center mb-5">
                  <h3 className="heading mb-3">
                    <span className="text-primary">Calculate </span>Business
                    Value
                  </h3>
                </div>
              </div>
            </div>
            <div className="row  g-4">
              <div className=" ">
                <h4>
                  <span className="text-primary"> STEP 1: </span>DETERMINE THE
                  CASH FLOW OF THE BUSINESS
                </h4>
                Discretionary Earnings are the Net Earnings of the business,
                before Interest, Taxes, Depreciation and Amortization, plus
                Manager's Salary and other non-recurring expenses. Only adjust
                for expenses listed on financial statements used for your
                valuation. For the current year, please estimate earnings for
                the full twelve months to ensure a proper valuation.
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Year</th>
                      {years.map((y) => (
                        <th scope="col">{y}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <span
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Amount of money brought in for selling a product or service for a specific year."
                        >
                          Sales{" "}
                          <i
                            style={{ color: "gray" }}
                            class="mdi mdi-information"
                          ></i>
                        </span>
                      </th>
                      {years.map((y) => {
                        return (
                          <td>
                            <span className="mr-2">$</span>
                            <CurrencyInput
                              name="sales"
                              id="sales"
                              placeholder="Sales"
                              defaultValue={businessvalue.sales}
                              decimalsLimit={5}
                              onValueChange={(value, name) =>
                                handleInputChange({ value, name }, y)
                              }
                            />
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th scope="row">
                        <span
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Direct costs of producing the goods sold by a company. This amount
                        includes the cost of the materials and labor directly used to create the good."
                        >
                          Costs of Goods Sold
                          <i
                            style={{ color: "gray" }}
                            class="mdi mdi-information"
                          ></i>
                        </span>
                      </th>
                      {years.map((y) => {
                        return (
                          <td>
                            <span className="mr-2">$</span>
                            <CurrencyInput
                              name="goods_sold"
                              id="goods_sold"
                              placeholder="Cost of Goods Sold"
                              defaultValue={businessvalue.goods_sold}
                              decimalsLimit={5}
                              onValueChange={(value, name) =>
                                handleInputChange({ value, name }, y)
                              }
                            />
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th scope="row">
                        <span
                          data-tooltip-content="Gross profit is the sum of all wages before deductions; gross profit is calculated
                        by subtracting the cost of goods sold from sales."
                          data-tooltip-id="my-tooltip"
                        >
                          Gross Profit
                          <i
                            style={{ color: "gray" }}
                            class="mdi mdi-information"
                          ></i>
                        </span>
                      </th>
                      {years.map((y) => {
                        return (
                          <td>
                            <span className="mr-2">$</span>
                            <input
                              value={
                                result[y]?.gross_profit.toLocaleString(
                                  "us-en"
                                ) ?? ""
                              }
                              type="text"
                              className=" bg-light"
                              placeholder="Gross Profit"
                              disabled
                            />
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th scope="row">
                        <span
                          data-tooltip-content="An expense that a business incurs through its normal business
                        operations. Some operating expenses include rent, equipment, inventory costs, marketing,
                        payroll, insurance step costs, and funds allocated for research and development."
                          data-tooltip-id="my-tooltip"
                        >
                          Operating Expenses
                          <i
                            style={{ color: "gray" }}
                            class="mdi mdi-information"
                          ></i>
                        </span>
                      </th>
                      {years.map((y) => {
                        return (
                          <td>
                            <span className="mr-2">$</span>

                            <CurrencyInput
                              name="operating_expenses"
                              id="operating_expenses"
                              placeholder="Operating Expenses"
                              defaultValue={businessvalue.operating_expenses}
                              decimalsLimit={5}
                              onValueChange={(value, name) =>
                                handleInputChange({ value, name }, y)
                              }
                            />
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th scope="row">
                        <span
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Net income is the amount of profit before taxes for the company. Net income is
                        calculated by subtracting operating expenses from gross profit."
                        >
                          Net Income
                          <i
                            style={{ color: "gray" }}
                            class="mdi mdi-information"
                          ></i>
                        </span>
                      </th>
                      {years.map((y) => {
                        return (
                          <td>
                            <span className="mr-2">$</span>
                            <input
                              className=" bg-light"
                              value={
                                result[y]?.netIncomeBeforeTax.toLocaleString(
                                  "us-en"
                                ) ?? ""
                              }
                              type="text"
                              placeholder="Net Income"
                              disabled
                            />
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th scope="row">
                        <span
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Items that can be added back into Net Income to get to Sellers Discretionary 
                        Earnings (SDE)."
                        >
                          ADD BACKS
                          <i
                            style={{ color: "gray" }}
                            class="mdi mdi-information"
                          ></i>
                        </span>
                      </th>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span
                          data-tooltip-content="The wages for an officer (this does not include normal payroll) according to
                          the IRS."
                          data-tooltip-id="my-tooltip"
                        >
                          Officer Salaries
                          <i
                            style={{ color: "gray" }}
                            class="mdi mdi-information"
                          ></i>
                        </span>
                      </th>
                      {years.map((y) => {
                        return (
                          <td>
                            <span className="mr-2">$</span>

                            <CurrencyInput
                              name="officer_salary"
                              id="officer_salary"
                              placeholder="Officer Salary"
                              defaultValue={businessvalue.officer_salary}
                              decimalsLimit={5}
                              onValueChange={(value, name) =>
                                handleInputChange({ value, name }, y)
                              }
                            />
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th scope="row">
                        <span
                          data-tooltip-content="An accounting method used to allocate the cost of a tangible or
                        physical asset over its useful life. Depreciation represents how much of an asset&#39;s value has
                        been used. It allows companies to earn revenue from the assets they own by paying for them
                        over a certain period of time."
                          data-tooltip-id="my-tooltip"
                        >
                          Depreciation
                          <i
                            style={{ color: "gray" }}
                            class="mdi mdi-information"
                          ></i>
                        </span>
                      </th>
                      {years.map((y) => {
                        return (
                          <td>
                            <span className="mr-2">$</span>

                            <CurrencyInput
                              name="depreciation"
                              id="depreciation"
                              placeholder="Depreciation"
                              defaultValue={businessvalue.depreciation}
                              decimalsLimit={5}
                              onValueChange={(value, name) =>
                                handleInputChange({ value, name }, y)
                              }
                            />
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th scope="row">
                        <span
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Interest is the monetary charge for the privilege of borrowing money (interest on a loan).
                        Interest expense or revenue is often expressed as a dollar amount, while the interest rate used
                        to calculate interest is typically expressed as an annual percentage rate (APR)."
                        >
                          Interest
                          <i
                            style={{ color: "gray" }}
                            class="mdi mdi-information"
                          ></i>
                        </span>
                      </th>
                      {years.map((y) => {
                        return (
                          <td>
                            <span className="mr-2">$</span>

                            <CurrencyInput
                              name="interest"
                              id="interest"
                              placeholder="Interest"
                              defaultValue={businessvalue.interest}
                              decimalsLimit={5}
                              onValueChange={(value, name) =>
                                handleInputChange({ value, name }, y)
                              }
                            />
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th scope="row">
                        <span
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Specific one-time events not included with the normal day-to-day operating
                        expenses, such as restructuring costs, inventory write-downs and lawsuits."
                        >
                          Other Expenses
                          <i
                            style={{ color: "gray" }}
                            class="mdi mdi-information"
                          ></i>
                        </span>
                      </th>
                      {years.map((y) => {
                        return (
                          <td>
                            <span className="mr-2">$</span>

                            <CurrencyInput
                              name="other_expenses"
                              id="other_expenses"
                              placeholder="Other Expenses"
                              defaultValue={businessvalue.other_expenses}
                              decimalsLimit={5}
                              onValueChange={(value, name) =>
                                handleInputChange({ value, name }, y)
                              }
                            />
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th scope="row">
                        <span
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="The total amount of all addbacks added together."
                        >
                          Total Add Backs
                          <i
                            style={{ color: "gray" }}
                            class="mdi mdi-information"
                          ></i>
                        </span>
                      </th>
                      {years.map((y) => {
                        return (
                          <td>
                            <span className="mr-2">$</span>
                            <input
                              className=" bg-light"
                              value={
                                result[y]?.totalAddBlock.toLocaleString(
                                  "us-en"
                                ) ?? ""
                              }
                              type="text"
                              placeholder="Total Add Backs"
                              disabled
                            />
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
                <h4>
                  <span className="text-primary"> STEP 2: </span> DETERMINE THE
                  MULTIPLE OF EARNINGS TO USE
                </h4>
                <SecondStep
                  earnings={earnings}
                  handleChangeMultipleEarning={handleChangeMultipleEarning}
                ></SecondStep>
                <h4>
                  <span className="text-primary"> STEP 3: </span> EARNINGS AND
                  RISK
                </h4>
                <ThirdStep
                  earningTrendValue={earningTrend}
                  handleRiskCheckboxChange={handleRiskCheckboxChange}
                  handleEarningTrendRadioChange={handleEarningTrendRadioChange}
                ></ThirdStep>
                <div className="mt-4 text-right ">
                  <button
                    className="btn btn-primary "
                    onClick={handleBusinessCalculation}
                  >
                    Start Calculation
                  </button>
                </div>
                <div ref={resultRef}>
                  <Result years={years} result={result}></Result>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer></Footer>
    </div>
  );
};
export default BusinessValue;
