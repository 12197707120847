import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Switch,
} from "react-router-dom";
import Signup from "../pages/user/auth/signup";
import Homepage from "../pages/homepage";
import Signin from "../pages/user/auth/signin";
import Register from "../pages/user/auth/register";
import Content from "../pages/admin/content";
import Course from "../pages/user/course";
import CourseContent from "../pages/user/course/courseContent";
import PrivateRoute from "./protectedRoutes";
import AdminRoute from "./admin";
import Admin_Course from "../pages/admin/CreateCourse";
import Notfound from "../pages/NotFound";
import ForgetPassword from "../pages/user/auth/forgetPassword/forgetPassword";
import ResetPassword from "../pages/user/auth/forgetPassword/resetPassword";
import AdminSignin from "../pages/admin/adminAuth/signin";
import AdminSignup from "../pages/admin/adminAuth/signup";
import UserManagement from "../pages/admin/userInfo";
import EventManagement from "../pages/admin/Events/index";
import EditInfo from "../pages/admin/updateUserInfo";
import GetAllCourses from "../pages/admin/getAllCourses";
import AdminCourseContent from "../pages/admin/viewCourseContent";
import TrainingRegister from "../pages/user/training/register";
import Create_Event from "../pages/admin/Events/CreateEvent";
import EditEvent from "../pages/admin/Events/EditEvent";
import Webinar from "../pages/user/webinar";
import Thanks from "../pages/user/thanks";
import AfterWelcomePage from "../pages/user/welcome";
import BusinessValue from '../pages/user/businessvalue'
function Navigator() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Notfound />}></Route>

        <Route exact path="/" element={<Homepage />}></Route>
        <Route exact path="/welcome" element={<AfterWelcomePage />}></Route>
        <Route exact path="/business-value" element={<BusinessValue />}></Route>
        <Route exact path="/admin-sign-in" element={<AdminSignin />}></Route>
        {/* <Route exact path="/admin-sign-up" element={<AdminSignup />}></Route> */}
        {/* 
        <Route exact path="/sign-up" element={<Signup />}></Route>
        <Route exact path="/sign-in" element={<Signin />}></Route> */}
        <Route exact path="/free-webinar" element={<Webinar />}></Route>
        <Route exact path="/webinar-thank-you" element={<Thanks />}></Route>
        <Route
          exact
          path="/training-register"
          element={<TrainingRegister />}
        ></Route>
        {/* <Route
          exact
          path="/forget-password"
          element={<ForgetPassword />}
        ></Route>
        <Route exact path="/reset-password" element={<ResetPassword />}></Route>

        <Route exact path="/register" element={<Register />}></Route>

        <Route element={<PrivateRoute />}>
          <Route exact path="/course" element={<Course />}></Route>
        </Route>
        <Route
          exact
          path={`/course-content/:id`}
          element={<CourseContent />}
        ></Route> */}

        <Route element={<AdminRoute />}>
          <Route exact path="/admin-content/:id" element={<Content />}></Route>
          <Route exact path="/admin-course" element={<Admin_Course />}></Route>
          <Route
            exact
            path="/user-mangement"
            element={<UserManagement />}
          ></Route>

          <Route
            exact
            path="/event-mangement"
            element={<EventManagement />}
          ></Route>
          <Route exact path="/create-event" element={<Create_Event />}></Route>
          <Route exact path="/edit-event/:id" element={<EditEvent />}></Route>

          <Route
            exact
            path="/user-edit-info/:id"
            element={<EditInfo />}
          ></Route>
          <Route
            exact
            path="/admin-course-mangement"
            element={<GetAllCourses />}
          ></Route>
          <Route exact path="/course" element={<Course />}></Route>
          {/* <Route
            exact
            path={`/course-content/:id`}
            element={<CourseContent />}
          ></Route> */}
          <Route
            exact
            path={`/admin-course-content/:id`}
            element={<AdminCourseContent />}
          ></Route>
        </Route>
      </Routes>
    </Router>
  );
}
export default Navigator;
