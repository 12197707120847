import React, { useEffect, useState } from "react";
import AdminNav from "../../../components/navbar/adminNav";
import adminApi from "../../../services/adminApi";
import { HiOutlineTrash, HiOutlinePencilAlt } from "react-icons/hi";

import { AiOutlineClose } from "react-icons/ai";
import { Navigate, useNavigate } from "react-router-dom";
import eventApi from "../../../services/eventApi";

const EventManagement = () => {
  const navigate = useNavigate();
  const [data, setdata] = useState();
  const [singdata, setsingdata] = useState();
  const [modal, setModal] = useState(false);
  const [updatemodal, setupdate] = useState(false);
  const [Uid, setUid] = useState();
  const [error, setError] = useState("");
  const [alertRed, setAlertRed] = useState(false);


  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [phone, setphone] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [street, setStreet] = useState("");
  const [card, setCard] = useState("");
  const [expiry, setexpiry] = useState("");
  const [cvc, setcvc] = useState("");

  useEffect(() => {
    fetchEventData();
  }, []);
  //   useEffect(() => {}, [data]);
  const openModal = () => {
    setModal(!modal);
  };
  const openUpdateModal = () => {
    setupdate(!updatemodal);
  };

  const fetchEventData = async () => {
    try {
      const response = await eventApi.fetchEvents();
      if (response.data.success) {
        setdata(response.data.payload);
      }
    } catch (err) {
      console.log("CATCH", err);
      setAlertRed(true);
      setError(`Server Error: Please check your internet connection`);
    }
  };

  const delete_event = async () => {
    try {
      const resp = await eventApi.deleteEvent(Uid);
      if (resp.data.success) {
        fetchEventData();
      }
    } catch (err) {
      console.log("ERROR", err);
      setAlertRed(true);
      setError(`Server Error: Please check your internet connection`);
    }
  };
  const setfnameHandler = (e) => {
    setfname(e);
  };
  const setlnameHandler = (e) => {
    setlname(e);
  };
  const setphoneHandler = (e) => {
    setphone(e);
  };
  const setzipHandler = (e) => {
    setZip(e);
  };
  const setcityHandler = (e) => {
    setCity(e);
  };
  const setstateHandler = (e) => {
    setState(e);
  };
  const setstreetHandler = (e) => {
    setStreet(e);
  };
  const setcardHandler = (e) => {
    setCard(e);
  };
  const setexpiryHandler = (e) => {
    // console.log("ee", a);
    setexpiry(e);
  };
  const setcvcHandler = (e) => {
    setcvc(e);
  };

  return (
    <div>
      <AdminNav />
      <div className="m-4 ">
        <div className="adminUserInfo">
          <div className="modal3__btn_layout">
            <div class=" mb-2 ml-1">
              <h4 class="">Event Details</h4>
            </div>

            <button
              className="btn btn-primary modal3__btn mr-4 "
              onClick={() => {
                navigate("/create-event");
              }}
            >
              + ADD
            </button>
          </div>


          {alertRed ? <div className=" font-alert">{error}</div> : ""}
          {data && data.length > 0 ? (
            <div className="mainLayoutUser">
              <div className="table_User">
                <table class="table main-table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Title</th>
                      <th scope="col">Description</th>
                      <th scope="col">Link</th>
                      <th scope="col">Date</th>
                      <th scope="col">Delete</th>
                      <th scope="col">Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((items) => (
                        <tr>
                          <td>{items.event_title}</td>
                          <td>{items.event_desc}</td>
                          <td>{items.event_link}</td>
                          <td>{items.event_date}</td>
                          <td>
                            {" "}
                            <HiOutlineTrash
                              className="delsvg"
                              onClick={() => {
                                openModal();
                                setUid(items._id);
                              }}
                            />
                          </td>
                          <td>
                            <HiOutlinePencilAlt
                              className="editsvg"
                              onClick={() => {
                                navigate(`/edit-event/${items._id}`);
                                setsingdata(items);
                                //   openUpdateModal();
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {/* <div className="">
                <button
                  className="btn btn-primary modal2__btn "
                  onClick={() => {
                    navigate("/admin-course");
                  }}
                >
                  Back
                </button>
              </div> */}
              </div>
            </div>
          ) : (
            <div class=" ">
              <div class="text-center mt-2">
                <h5>No Event was available.</h5>
              </div>
            </div>
          )}

          {/* <!-- Modal --> */}
          {modal ? (
            <section className="modal1__bg">
              <div
                class="modal-btn"
                onClick={openModal}
                data-bs-toggle="modal"
                data-bs-target=".watchvideomodal"
              ></div>
              <div className="modal1__align">
                <div className="modal1__content" modal={modal}>
                  <div className="modal1__msg">
                    <h5>Are you sure you want to delete this user? </h5>
                    {alertRed ? <div className=" font-alert">{error}</div> : ""}
                    <div className="modal1__btn_layout">
                      <button
                        className="btn btn-primary modal1__btn delete_btn_content"
                        onClick={() => {
                          openModal();
                          delete_event();
                        }}
                      >
                        {/* {loader ? (
                        <i class="fa fa-circle-o-notch fa-spin mr-4"></i>
                      ) : (
                        ""
                      )} */}
                        Delete
                      </button>
                      <button
                        className="btn btn-primary modal1__btn "
                        onClick={openModal}
                      >
                        {/* {loader ? (
                        <i class="fa fa-circle-o-notch fa-spin mr-4"></i>
                      ) : (
                        ""
                      )} */}
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
          {/* <!-- END MODAL --> */}

          {/* <!-- Update Modal --> */}
          {updatemodal ? (
            <section className="modal2__bg">
              <div
                class="modal-btn"
                onClick={openUpdateModal}
                // href="javascript:void(0)"
                data-bs-toggle="modal"
                data-bs-target=".watchvideomodal"
              ></div>
              <div className="modal2__align">
                <div className="modal2__content" modal={modal}>
                  <div className="modal2__msg">
                    <div className="reg reg-scroll">
                      <div class="form-group">
                        <input
                          value={fname}
                          onChange={(e) => setfnameHandler(e.target.value)}
                          class="modal2__input"
                          type="text"
                          name="firstname"
                          placeholder="First Name"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={lname}
                          class="form-control"
                          onChange={(e) => setlnameHandler(e.target.value)}
                          type="text"
                          name="password"
                          placeholder="Last Name"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={phone}
                          class="form-control"
                          onChange={(e) => setphoneHandler(e.target.value)}
                          type="text"
                          name="password-repeat"
                          placeholder="Phone"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={zip}
                          class="form-control"
                          onChange={(e) => setzipHandler(e.target.value)}
                          type="text"
                          name="password-repeat"
                          placeholder="Zip"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={city}
                          class="form-control"
                          onChange={(e) => setcityHandler(e.target.value)}
                          type="text"
                          name="password-repeat"
                          placeholder="City"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={state}
                          class="form-control"
                          onChange={(e) => setstateHandler(e.target.value)}
                          type="text"
                          name="password-repeat"
                          placeholder="State"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={street}
                          class="form-control"
                          onChange={(e) => setstreetHandler(e.target.value)}
                          type="text"
                          name="password-repeat"
                          placeholder="Street"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={card}
                          class="form-control"
                          onChange={(e) => setcardHandler(e.target.value)}
                          type="text"
                          name="password-repeat"
                          placeholder="Card"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={cvc}
                          class="form-control"
                          onChange={(e) => setcvcHandler(e.target.value)}
                          type="text"
                          name="CVC"
                          placeholder="CVC"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          value={expiry}
                          class="form-control"
                          onChange={(e) => setexpiryHandler(e.target.value)}
                          type="month"
                          name="password-repeat"
                          placeholder="Expiry"
                        />
                      </div>
                    </div>
                    {/* <h5>Are you sure you want to delete this user? </h5> */}
                    <div className="modal2__btn_layout">
                      <button
                        className="btn btn-primary  modal2__btn "
                        onClick={() => {
                          openUpdateModal();
                          delete_event();
                        }}
                      >
                        {/* {loader ? (
                        <i class="fa fa-circle-o-notch fa-spin mr-4"></i>
                      ) : (
                        ""
                      )} */}
                        Update
                      </button>
                      <button
                        className="btn btn-primary modal2__btn "
                        onClick={openUpdateModal}
                      >
                        {/* {loader ? (
                        <i class="fa fa-circle-o-notch fa-spin mr-4"></i>
                      ) : (
                        ""
                      )} */}
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
          {/* <!-- END Update MODAL --> */}
        </div>
      </div>
    </div>
  );
};
export default EventManagement;
