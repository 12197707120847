import React from "react";

const Training = () => {
  return (
    <section class="section features" id="features">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mb-5">
              <h3 class="heading">
                TRAINING <span class="text-primary">INCLUDES</span>
              </h3>
              <p class="text-muted mb-0">Exclusive Items</p>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="row align-items-center justify-content-center g-3">
              <div class="col-lg-3">
                <div class="row mx-auto g-3">
                  <div class="col-lg-12 mb-4">
                    <div class="card features-box border-0">
                      <div class="card-body features-body">
                        <div class="d-flex">
                          <div class="flex-shrink-0 mr-2">
                            <div class="align-items-center">
                              <div class="features-icon">
                                <i class="mdi mdi-earth"></i>
                              </div>
                            </div>
                          </div>
                          <div class="flex-grow-1 align-self-center ms-3">
                            <h6 class="mb-0">ONLINE TRAINING</h6>
                            <span class="text-muted">
                              2 days of online interactive training
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="card features-box border-0">
                      <div class="card-body features-body">
                        <div class="d-flex">
                          <div class="flex-shrink-0 mr-2">
                            <div class="align-items-center">
                              <div class="features-icon">
                                <i class="mdi mdi-notebook-multiple"></i>
                              </div>
                            </div>
                          </div>
                          <div class="flex-grow-1 align-self-center ms-3">
                            <h6 class="mb-0">COURSE MANUAL</h6>
                            <span class="text-muted">
                              Learn when to buy or sell a stock while creating
                              consistent monthly cash flow
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="card features-box border-0">
                  <div class="card-body features-body p-0">
                    <img
                      src={require("../../../images/features.png")}
                      class="img-fluid features-img"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="row mx-auto g-3">
                  <div class="col-lg-12 mb-4 px-2">
                    <div class="card features-box border-0">
                      <div class="card-body features-body">
                        <div class="d-flex">
                          <div class="flex-shrink-0 mr-2 ">
                            <div class="align-items-center">
                              <div class="features-icon">
                                <i class="mdi mdi-security"></i>
                              </div>
                            </div>
                          </div>
                          <div class="flex-grow-1 align-self-center ms-3">
                            <h6 class="mb-0">FREE WEBSITE ACCESS</h6>
                            <span class="text-muted">
                              60 days of website access, ongoing monthly access
                              is then just $24.99 BONUS: Quick Start Video,
                              Covered Call Video, Finance for Kids Videos
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="card features-box border-0">
                      <div class="card-body features-body">
                        <div class="d-flex">
                          <div class="flex-shrink-0 mr-2">
                            <div class="align-items-center">
                              <div class="features-icon ">
                                <i class="mdi mdi-note-outline"></i>
                              </div>
                            </div>
                          </div>
                          <div class="flex-grow-1 align-self-center ms-3">
                            <h6 class="mb-0 ">PORTFOLIO REVIEW</h6>
                            <span class="text-muted">
                              Complete portfolio review of your current
                              portfolio
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Training;
