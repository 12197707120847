import React from "react";
import { Outlet, Navigate } from "react-router-dom";

// import Sidebar from "../components/sidebar";
// import Navbar from "../components/navbar";
function PrivateRoute() {
  let auth = {
    token: localStorage.getItem("token"),
    isAdmin: localStorage.getItem("Admin"),
  };

  return (auth?.isAdmin && auth?.token) ? <Outlet /> : <Navigate to="/" />;
}

export default PrivateRoute;
