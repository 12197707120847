import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminNav from "../../../components/navbar/adminNav";
import adminApi from "../../../services/adminApi";
import "../../../css/formSignin.css";
import * as moment from "moment";
import eventApi from "../../../services/eventApi";
const EditEvent = () => {
  useEffect(() => {
    const pathname = window.location.pathname;
    const pathParts = pathname.split("/");
    const id = pathParts[2];
    get_event(id);
  }, []);

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [eventName, setEventName] = useState("");
  const [eventLink, setEventLink] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventDesc, setEventDesc] = useState("");

  const get_event = async (id) => {
    try {
      const resp = await eventApi.findEvent(id);
      if (resp.data.success) {
        setEventName(resp.data.payload.event_title);
        setEventLink(resp.data.payload.event_link);
        setEventDate(resp.data.payload.event_date);
        setEventDesc(resp.data.payload.event_desc);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateEventDetails = async () => {
    const pathname = window.location.pathname;
    const pathParts = pathname.split("/");
    const id = pathParts[2];

    const value = {
      id: id,
      event_title: eventName,
      event_desc: eventDesc,
      event_link: eventLink,
      event_date: eventDate,
    };
    setLoader(true);
    try {
      const response = await eventApi.updateEvent(value);
      if (response.data.success == true) {
        setLoader(false);
        navigate("/event-mangement");
      }
    } catch (err) {}
  };

  return (
    <div className="m-4 ">
      <AdminNav />

      <div className="admin">
        <div className="mb-4">
          <h5>Event Info</h5>
        </div>
        <div className="mb-4">
          <h6>Event Title</h6>
          <div className="admin-input">
            <input
              value={eventName}
              className="w-25  admin-input-content "
              type="text"
              name="text"
              placeholder="Enter title for your Course"
              onChange={(e) => setEventName(e.target.value)}
            />
          </div>
        </div>

        <div className="mb-4">
          <h6>Event Link</h6>
          <div className="admin-input">
            <input
              value={eventLink}
              className="w-25  admin-input-content "
              type="text"
              name="text"
              placeholder="Enter title for your Course"
              onChange={(e) => setEventLink(e.target.value)}
            />
          </div>
        </div>

        <div className="mb-4">
          <h6>Event Date</h6>
          <div className="admin-input">
            <input
              value={eventDate}
              className="w-25  admin-input-content "
              type="text"
              name="date"
              placeholder="Mar 19th Sun, 11am Eastern"
              onChange={(e) => setEventDate(e.target.value)}
            />
          </div>
        </div>

        <div className="mb-4">
          <h6>Event Description</h6>
          <div className="admin-input">
            <textarea
              value={eventDesc}
              className="w-50  admin-input-content "
              type="text"
              rows={4}
              name="text"
              placeholder="Enter Description for your Course"
              onChange={(e) => setEventDesc(e.target.value)}
            />
          </div>
        </div>

        <div className="modal1__btn_layout admin_btn_layout mt-5">
          <button
            className="btn update_btn_content modal1__btn "
            onClick={() => {
              updateEventDetails();
            }}
          >
            {loader ? <i class="fa fa-circle-o-notch fa-spin mr-4"></i> : ""}
            Update
          </button>
          <button
            className="btn btn-primary modal1__btn "
            onClick={() => {
              navigate("/event-mangement");
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};
export default EditEvent;
