import React from "react";
import { useNavigate, Link } from "react-router-dom";
const AuthNav = () => {
  const navigate = useNavigate();
  return (
    <nav
      class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky"
      id="navbar"
    >
      <div class="container">
        <a class=" text-uppercase ardLogo" onClick={() => navigate("/")}>
          <img
            class="logo-light"
            src={require("../../images/PNG-01.png")}
            alt="ARD-EDU"
            height="85"
          />
        </a>
        {!localStorage.getItem("isAuthenticated") && (
          <button
            class="btn btn-primary nav-btn"
            onClick={() =>
              window.open("https://event.webinarjam.com/register/2/2ypk8cl")
            } //navigate("/free-webinar")}
          >
            Get Free Training
          </button>
        )}
      </div>

      {/* <!-- End container --> */}
    </nav>
  );
};

export default AuthNav;
