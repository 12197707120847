import React from "react";

const About = ({ id }) => {
  return (
    <section class=" " id="about">
      <div class="container">
        <div class="row justify-content-center ">
          <div class="col-lg-12">
            <div class="text-center mb-5">
              <h3 class="heading">
                <span class="text-primary">About</span> Us
              </h3>
              <p>
                The best way to learn is through active participation. You will
                not be required to trade during this course but you should
                follow along as much as you can.
              </p>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade active show"
                id="pills-privacy_policy"
                role="tabpanel"
                aria-labelledby="pills-privacy_policy-tab"
              >
                <div class="row justify-content-between align-items-center">
                  <div class="col-lg-5">
                    <div class="about-img  ">
                      <img
                        src={require("../../../images/about/img-1.png")}
                        class="img-fluid "
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <p class="text-muted">
                      Every business day in America more and more investors make
                      the decision to switch from a passive investing role to a
                      more hands-on investing style. In doing so they also make
                      the commitment to continue their financial education. This
                      creates a need for accurate, timely information, which can
                      be applied, to investing strategies to increase the
                      profitability levels of a portfolio. Our goal is to
                      introduce to you a new world of information, ideas,
                      strategies, stocks, and options that you can use to become
                      a successful investor. Our website was designed for the
                      serious trader but our stock analysis and detailed trading
                      picks can be followed by even those new to the markets.
                    </p>
                    <p class="text-muted">
                      A major feature of our website is our trading ideas
                      section. We continue to outline new trading ideas
                      including entrance, exit, AND reversal points. We cover
                      topics surrounding these picks and which trading strategy
                      will be best used to profit from the stock. These may be
                      Stock Splits, Earnings Plays, Technical Sector Rotations,
                      or even IRA Candidates. It gets even better, since we
                      donate 100% of our trading profits to charities.
                    </p>

                    <h6 class="text-primary">The Bottom Line</h6>
                    <p class="text-muted">
                      From beginner to advanced, our intricate web of stock
                      analysts and professional traders will help you in making
                      the right choices for your account. At Incometrader.com
                      "Your success is Our success."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
