import axios from "../axios";

import {
  ADMIN_SIGN_IN,
  ADMIN_SIGN_UP,
  DELETE_CONTENT,
  DELETE_COURSE,
  DELETE_USER,
  FIND_USER,
  GET_ADMIN_DATA,
  GET_ALL_USER_DATA,
  UPDATE_USER_DETAIL,
  update_course
} from "../api/apiRoutes";

export default {
  adminSignUp: async (payload) => {
    const response = await axios.post(ADMIN_SIGN_UP, payload);
    return response;
  },
  adminSignIn: async (payload) => {
    console.log("payload", payload);
    const response = await axios.post(ADMIN_SIGN_IN, payload);
    return response;
  },
  updateUserDetail: async (payload) => {
    console.log("payload", payload);
    const response = await axios.post(UPDATE_USER_DETAIL, payload);
    return response;
  },
  getAdminData: async () => {
    const reponse = await axios.get(GET_ADMIN_DATA);
    return reponse;
  },
  getUserData: async () => {
    const reponse = await axios.get(GET_ALL_USER_DATA);
    return reponse;
  },
  deleteUser: async (id) => {
    const response = await axios.get(`${DELETE_USER}/${id}`);
    return response;
  },
  deleteCourse: async (id) => {
    const response = await axios.get(`${DELETE_COURSE}/${id}`);
    return response;
  },
  updateCourse: async (payload) => {
    const response = await axios.post(update_course,payload);
    return response;
  },
  deleteContent: async (id) => {
    const response = await axios.get(`${DELETE_CONTENT}/${id}`);
    return response;
  },

  findUser: async (id) => {
    const response = await axios.get(`${FIND_USER}/${id}`);
    return response;
  },
};
