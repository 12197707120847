const Result = ({ result, years }) => {
  return Object.keys(result).length ? (
    <>
      <h4>
        <span class="text-primary">Business Value Result</span>
      </h4>
      <div className="d-flex justify-content-center">
        {years.map((y) => {
          return (
            <div class="col-lg-4">
              <div
                style={{ backgroundColor: "#FAEFEF" }}
                class="card features-box border-0"
              >
                <div class="card-body features-body">
                  <div class="d-flex">
                    <div class="flex-shrink-0 mr-2 ">
                      <div class="align-items-center">
                        <div class="features-icon">
                          <i className="mdi mdi-currency-usd"></i>
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1 align-self-center ms-3">
                      <h6 class="mb-0 text-black">Year {y}</h6>
                      <span class="text-muted">
                        Business Calculated value for the year {y} is: $
                        <b>{parseFloat(result[y]?.calcResult).toLocaleString("us-en")}</b>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  ) : (
    ""
  );
};
export default Result;
